import { createSlice } from '@reduxjs/toolkit';
import {getSelectableSectionFragmentsSucceededAction} from './Actions';
import {getSelectableSectionFragmentsSucceededReducer,} from './Reducers';
import {selectableSectionFragmentsState} from "./State";

export const selectableSectionFragmentsSlice = createSlice({
  name: 'selectableSectionFragments',
  initialState: selectableSectionFragmentsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSelectableSectionFragmentsSucceededAction, getSelectableSectionFragmentsSucceededReducer)
  },
});
