import {SelectableSectionFragmentsService, getSelectableSectionFragmentsAction, getSelectableSectionFragmentsSucceededAction} from './index';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {
  getApplicationService,
  getOrderSelector,
  getSelectedConfigurationTemplateSelector,
  storeErrorAction
} from "../../";
import {ConfigurationTemplateDto, FrameDto, OrderDto} from "@hec/api-dtos";

function* handleGetSelectableSectionFragments() {
  const service: SelectableSectionFragmentsService = yield getApplicationService(SelectableSectionFragmentsService);

  const selectedConfigurationTemplate: ConfigurationTemplateDto = yield select(getSelectedConfigurationTemplateSelector);
  const order: OrderDto | null = yield select(getOrderSelector);

  try {
    if (!selectedConfigurationTemplate) {
      yield put(storeErrorAction({
        error: new Error('No configuration template selected')
      }));
      return;
    }
    if (!order) {
      yield put(storeErrorAction({
        error: new Error('No order found')
      }));
      return;
    }

    const result: FrameDto[] = yield call([service, 'get'], selectedConfigurationTemplate.id, order.configurationCode);
    yield put(getSelectableSectionFragmentsSucceededAction({ sectionFragmentsDto: result}));

  } catch (error) {
    yield put(storeErrorAction({error: error}));
  }
}


export function* SelectableSectionFragmentsSaga() {
  yield takeLatest(getSelectableSectionFragmentsAction, handleGetSelectableSectionFragments);
}
