import {BaseDalService} from "@hec/core";
import {AppConfiguration, ApplicationService} from "@hec/models";
import axios, {AxiosInstance, CancelTokenSource} from "axios";
import {OrderDto} from "@hec/api-dtos";
import _ from "lodash";
import { MD5 } from 'crypto-js';
import {HashObject} from "@hec/utils";
import {getSectionFragmentsFromOrder, getSouthFaceFromOrder} from "../Util";


export class PutOrderService extends BaseDalService implements ApplicationService {

  private lastPutHash: string | undefined;
  private client: AxiosInstance;
  public static override serviceName = Symbol('PutOrderService');
  private cancelTokenSource: CancelTokenSource | undefined;

  constructor() {
    super();
    this.client = axios.create({});
  }

  override configure(configuration: AppConfiguration) {
    this.client = axios.create({
      baseURL: `${configuration.baseUrl}/api`,
    });

    this.useDateTimeConverters(this.client);
    this.useEnumConverters(this.client);
  }


  getName(): symbol {
    return PutOrderService.serviceName;
  }

  static override getName(): symbol {
    return PutOrderService.serviceName;
  }

  hashObject(obj: any): string {
    return HashObject(obj);
  }



  async putOrder(orderInput: OrderDto, orderConfigurationCode: string): Promise<PutOrderResult | null> {
    this.configureAuthInterceptor(this.client, orderConfigurationCode);

    function makeAllPropertiesWritable<T>(obj: T): T {
      if (typeof obj !== 'object' || obj === null) {
        return obj;
      }

      Object.keys(obj).forEach(key => {
        const descriptor = Object.getOwnPropertyDescriptor(obj, key);
        if (descriptor && !descriptor.writable) {
          Object.defineProperty(obj, key, {
            ...descriptor,
            writable: true
          });
        }

        // Recurse into nested objects
        const value = (obj as any)[key];
        if (typeof value === 'object' && value !== null) {
          makeAllPropertiesWritable(value);
        }
      });

      return obj;
    }

    const order: OrderDto = _.cloneDeep(orderInput);

    makeAllPropertiesWritable(order);

    let sectionFragments = getSectionFragmentsFromOrder(order)


    sectionFragments = sectionFragments.map(sf=> {
      sf.availableDormerMaterials = [];
      sf.configuredDormerColor = null;

      sf.availableDormerMaterialsSideRods = [];
      sf.configuredDormerColorSideRods = null;

      if(sf.copiedFrom) {
        sf.copiedFrom.availableDormerMaterials = [];
        sf.copiedFrom.configuredDormerColor = null;

        sf.availableDormerMaterialsSideRods = [];
        sf.configuredDormerColorSideRods = null;
      }
      return sf;
    });

    const southFace = getSouthFaceFromOrder(order);

    southFace!.sectionFragments = sectionFragments;


    const newSectionFragmentIds = sectionFragments.filter(x=> x.id != null).map(x=> x.id);
    const duplicatIdSectionFragments = sectionFragments.filter(x=> newSectionFragmentIds.filter(id => x.id == id).length > 1);

    if(duplicatIdSectionFragments.length > 0) {
      console.error("DUPLICATE ID REPLACEMENT ERR!");
    }


    const curOrderHash = this.hashObject(order);

    if (curOrderHash === this.lastPutHash) {
      return {
        order,
        modified: false,
      };
    }

    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('New order request initiated');
    }

    this.cancelTokenSource = axios.CancelToken.source();
    this.lastPutHash = curOrderHash;

    try {
      const response = await this.client.put(`/orders/${order.id}`, order, {
        cancelToken: this.cancelTokenSource.token,
      });

      if (response.status === 200) {
        return {
          order: response.data as OrderDto,
          modified: true,
        };
      }

      throw new Error(`PutOrderService: putOrder resulted in ${response.status}: ${response.statusText}`);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Order Request canceled due newer one start');
        return null;
      } else {
        throw error;
      }
    }
  }
}

export interface PutOrderResult {
  order: OrderDto;
  modified: boolean;
}
