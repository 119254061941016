import {OrbitControls, SoftShadows, useHelper} from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React, {FunctionComponent, useRef} from 'react';
import {DirectionalLight, DirectionalLightHelper} from "three";
import {Conditional} from "@hec/components/v1";

interface CameraProps {
  dolly?: boolean;
  zoom?: number;
  isDevelopmentBuild?: boolean
  offSet?: { x: number, y: number, z: number}
  tier?: number;
}

export const DEFAULT_ZOOM = 3;
const azimuthAngle = Math.PI / 2;
const maxPolar = Math.PI / 2 - Math.PI / 360;


export const CameraRevised: FunctionComponent<CameraProps> = ({
  zoom = DEFAULT_ZOOM,
  dolly = true,
  isDevelopmentBuild = false,
  offSet,
  tier = 0,
}) => {
  const { camera } = useThree();
  const minDistance = 0;

  const dirLight = useRef<DirectionalLight>(null);

  // tier = 3;

  // const shadowMapSize = tier === 3 ? 6144 : tier === 2 ? 3072 : 1024;
  const shadowMapSize = tier === 3 ? 6144 : tier === 2 ? 3072 : 1024;
  const enableShadows = tier > 1;

  // useHelper(isDevelopmentBuild ? dirLight : null, DirectionalLightHelper, 1, "red");


  return (
    <group>
      <ambientLight intensity={0.33 * Math.PI} />
      <directionalLight
        ref={dirLight}
        castShadow={enableShadows}
        position={[5, 5, 5]}
        intensity={2.9 * Math.PI}
        shadow-mapSize-width={shadowMapSize}
        shadow-mapSize-height={shadowMapSize}

        // shadow-normalBias={0.01}
        // This fixes shadows on touching meshes: https://discourse.threejs.org/t/pixel-shadows-between-adjacent-meshes/41951/3
        shadow-bias={-0.00004}
      />
      <OrbitControls
        enableZoom={true}
        enableRotate={true}
        enableDamping={true}
        maxDistance={25}
        minDistance={minDistance}
        // target={target}
        camera={camera}
        maxPolarAngle={isDevelopmentBuild ? undefined : maxPolar}
        minAzimuthAngle={isDevelopmentBuild ? undefined : -azimuthAngle}
        maxAzimuthAngle={isDevelopmentBuild ? undefined : azimuthAngle}
        enablePan={isDevelopmentBuild}
        target={offSet != null ? [offSet.x, offSet.y, offSet.z] : [0, 0, 0]}
      />
      <Conditional condition={tier === 3}>
        <SoftShadows/>
      </Conditional>
    </group>
  );
};
