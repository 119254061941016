import React, {FunctionComponent, useMemo} from "react";
import {fromMmToRenderingMeters} from "@hec/core";
import {createRollerShutterShape} from "../CreateRollerShutterShape";
import {FrameOverlayRollerShuttersProps} from "./FrameOverlayRollerShuttersProps";
import {distanceFromZeroZ, GuideRailRenderer, screenTexture} from "./index";
import {DormerColorMaterial} from "../../DormerColorMaterial";
import {DoubleSide, TextureLoader} from "three";
import {WrappingUtility} from "@hec/components/v2";
import {useLoader} from "@react-three/fiber";

const spaceBetweenLamelHeight = fromMmToRenderingMeters(5);


export const FrameOverlayRollerBlindsRenderer: FunctionComponent<FrameOverlayRollerShuttersProps> =
  ({startCoordinateX, endCoordinateX, width, totalHeight, primaryColor, secondaryColor}: FrameOverlayRollerShuttersProps) => {



    const textureMap =  useLoader(TextureLoader, screenTexture);


    const materialSiderod = <DormerColorMaterial
      dormerColor={secondaryColor ?? primaryColor}
    />


    // Naar spec: https://www.rolluiken33.nl/alutech-basisline-rolluik.html

    let renderingWidth = fromMmToRenderingMeters(width);
    // We are supposed to cover this distance with rolling parts only,
    // so the rails dont count over the renderingWidth hence we need to add the rails distance.
    const guideRailWidthMM = 55;
    const guideRailWidth = fromMmToRenderingMeters(guideRailWidthMM);
    renderingWidth += guideRailWidth * 2;

    const lamelHeightMm = 40;
    const lamelHeight = fromMmToRenderingMeters(lamelHeightMm)
    const lamelDepth = fromMmToRenderingMeters(9);

    const amountOfLammellen = Math.round(totalHeight / lamelHeightMm / 3);


    const shape = useMemo(() => createRollerShutterShape(), []);

    const gapSpace = fromMmToRenderingMeters(15);
    const betweenGapSpace = fromMmToRenderingMeters(17);
    const gapTotal = gapSpace + betweenGapSpace;

    const gridRepeatForGapX = Math.ceil(renderingWidth / gapTotal);

    const sideRod = <mesh key={'siderod'} castShadow={true} receiveShadow={true}>
      <GuideRailRenderer
        heightMM={totalHeight}
        innerDepthMM={8}
        widthMM={guideRailWidthMM}
      />
      {materialSiderod}
    </mesh>;

    const bottomBarDepth = fromMmToRenderingMeters(6);
    const bottomBarHeight = fromMmToRenderingMeters(50);

    const gapCreationRodHeight = amountOfLammellen * (lamelHeight + spaceBetweenLamelHeight);


    const bar = <extrudeGeometry args={[shape, {depth: renderingWidth, bevelEnabled: false, curveSegments: 4}]}/>;

    const repeatAmount = 6;

    const wrapped = WrappingUtility.wrapTexture(textureMap, renderingWidth * repeatAmount, gapCreationRodHeight * repeatAmount)
    const materialPrimary = <meshStandardMaterial
      attach={undefined}
      color={`#${primaryColor?.colorCode}`}
      roughness={1}
      metalness={0.01}
      side={DoubleSide}
      map={wrapped}
    />

    return <group
      position={[(renderingWidth / 2) + -guideRailWidth + fromMmToRenderingMeters(startCoordinateX), 0, distanceFromZeroZ]}>
      <group key={'screen'} position={[0, (fromMmToRenderingMeters(totalHeight) - gapCreationRodHeight), 0]}>
        <group key={'rows'}>
          <mesh position={[0, gapCreationRodHeight / 2, fromMmToRenderingMeters(-5)]} castShadow={true} receiveShadow={true}>
            <planeGeometry args={[renderingWidth, gapCreationRodHeight]}/>
            {materialPrimary}
          </mesh>
          {/*{Array.from({length: amountOfLammellen}).map((_, index) =>*/}
          {/*  (<group*/}
          {/*    position={[-renderingWidth / 2, index * (lamelHeight + spaceBetweenLamelHeight), 0]}*/}
          {/*    rotation={[0, Math.PI / 2, 0]}>*/}
          {/*    <mesh>*/}
          {/*      {bar}*/}
          {/*      {materialPrimary}*/}
          {/*    </mesh>*/}
          {/*  </group>))*/}
          {/*}*/}
        </group>
        {/*<group*/}
        {/*  position={[-renderingWidth / 2, gapCreationRodHeight / 2 + -spaceBetweenLamelHeight, -lamelDepth / 2 + fromMmToRenderingMeters(1) / 2]}>*/}
        {/*  {Array.from({length: gridRepeatForGapX}).map((_, index) =>*/}
        {/*    (<group>*/}
        {/*      <mesh position={[index * gapTotal + (betweenGapSpace / 2), 0, 0]}>*/}
        {/*        <boxGeometry args={[betweenGapSpace, gapCreationRodHeight, fromMmToRenderingMeters(2)]}/>*/}
        {/*        {materialPrimary}*/}
        {/*      </mesh>*/}
        {/*    </group>))*/}
        {/*  }*/}
        {/*</group>*/}
        <mesh key={`bottomBar`}
              castShadow={true} receiveShadow={true}
              position={[0, -(bottomBarHeight / 2), -bottomBarDepth / 2]}>
          <boxGeometry
            args={[renderingWidth, bottomBarHeight, bottomBarDepth]}
          />
          {materialSiderod}
        </mesh>
      </group>
      <group
        key={'siderod-left'}
        rotation={[Math.PI / 2, 0, 0]}
        position={[-renderingWidth / 2, fromMmToRenderingMeters(totalHeight), -fromMmToRenderingMeters(10)]}
      >
        {sideRod}
      </group>
      <group
        key={'siderod-right'}
        rotation={[Math.PI / 2, Math.PI, 0]}
        position={[renderingWidth / 2, 0, -fromMmToRenderingMeters(10)]}
      >
        {sideRod}
      </group>
    </group>
  }
