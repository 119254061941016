import { GroundDimensions } from './CalculateGroundDimensions';
import { FunctionComponent, useMemo, useRef } from 'react';
import { useTexture } from '@react-three/drei';
import { Color, FrontSide, RepeatWrapping, Vector3 } from 'three';
import {Ocean} from "./Ocean";
// import { Ocean } from 'react-three-ocean';

const tileCdnUrl =
  'https://cdn.aanbouwconfigurator.com/static-assets/generic/garden/tile.jpg';

interface WaterFeatureProps {
  groundDimensions: GroundDimensions;
}

export const WaterFeature: FunctionComponent<WaterFeatureProps> = ({
  groundDimensions,
}: WaterFeatureProps) => {
  const {
    width,
    depth,
    tileWidth,
    grassWidth,
    grassDepth,
    zOffsetTile,
    xOffsetTile,
    zOffsetGrass,
    xOffsetGrass,
    westFenceDepth,
    zPositionWestFence,
    eastFenceDepth,
    zPositionEastFence,
    southFenceWidth,
    zPositionSouthFence,
  } = groundDimensions;

  const map = useTexture(tileCdnUrl);
  map.wrapS = RepeatWrapping;
  map.wrapT = RepeatWrapping;

  map.repeat.set(1, 1);

  const wallThickness = 0.15;
  const wallHeight = 0.2;
  const wallLength = 1.5;

  const distanceFromOtherObjects = 0.25;
  const wallLengthDeep = grassDepth - distanceFromOtherObjects * 2;

  const waterFeatureColor = new Color('rgb(7, 143, 122)');

  return (
    <group
      position={[
        distanceFromOtherObjects,
        0,
        zOffsetGrass + distanceFromOtherObjects,
      ]}
    >
      {/*NORTH*/}
      <group position={[wallLength / 2, wallHeight / 2, wallThickness / 2]}>
        <mesh receiveShadow castShadow>
          <boxGeometry args={[wallLength, wallHeight, wallThickness]} />
          <meshBasicMaterial attach="material" map={map} />
        </mesh>
      </group>
      {/*SOUTH*/}
      <group
        position={[
          wallLength / 2,
          wallHeight / 2,
          wallLengthDeep - wallThickness / 2,
        ]}
      >
        <mesh receiveShadow castShadow>
          <boxGeometry args={[wallLength, wallHeight, wallThickness]} />
          <meshBasicMaterial attach="material" map={map} />
        </mesh>
      </group>
      {/*West*/}
      <group position={[wallThickness / 2, wallHeight / 2, wallLengthDeep / 2]}>
        <mesh receiveShadow castShadow>
          <boxGeometry
            args={[
              wallThickness,
              wallHeight,
              wallLengthDeep - wallThickness * 2,
            ]}
          />
          <meshBasicMaterial attach="material" map={map} />
        </mesh>
      </group>
      {/*  East*/}
      <group
        position={[
          wallLength - wallThickness / 2,
          wallHeight / 2,
          wallLengthDeep / 2,
        ]}
      >
        <mesh receiveShadow castShadow>
          <boxGeometry
            args={[
              wallThickness,
              wallHeight,
              wallLengthDeep - wallThickness * 2,
            ]}
          />
          <meshBasicMaterial attach="material" map={map} />
        </mesh>
      </group>
      <group position={[wallLength / 2, wallHeight - 0.05, wallLengthDeep / 2]}>
        <Ocean
          dimensions={[wallLength - 0.001, wallLengthDeep - 0.001]}
          normals="https://raw.githubusercontent.com/mrdoob/three.js/master/examples/textures/waternormals.jpg"
          distortionScale={20}
          size={100}
          options={{
            // defaults
            clipBias: 0,
            alpha: 1,
            // waterNormals: null, // automatically set to provided texture from "normals" prop
            sunDirection: new Vector3(0.70707, 0.70707, 0),
            sunColor: 0xffffff,
            waterColor: waterFeatureColor,
            eye: new Vector3(0, 0, 0),
            distortionScale: 3.7, // automatically set from "distortionScale" prop
            side: 0,
            fog: false,
          }}
        />
      </group>
    </group>
  );
};
