import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import { Object3D } from 'three';
import React, {FunctionComponent, PropsWithChildren, useEffect, useRef} from 'react';
import { Canvas } from '@react-three/fiber';
import { Group } from 'three';

export const exportGLB = (object: Object3D, fileName = 'scene') => {
  const exporter = new GLTFExporter();

  exporter.parse(
    object,
    (result) => {
      if (result instanceof ArrayBuffer) {
        // Handle binary GLB data
        const blob = new Blob([result], { type: 'model/gltf-binary' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        // link.download = fileName;
        link.download = `${fileName}.glb`;
        link.click();
      } else {
        console.error('GLTFExporter output was not an ArrayBuffer.');
      }
    },
    (error) => {
      console.error('An error occurred during GLTF export:', error);
    },
    { binary: true } // Export as a binary GLB
  );

  // Export as GLTF
  exporter.parse(
    object,
    (result) => {
      if (typeof result === 'object') {
        const gltfData = JSON.stringify(result, null, 2);
        const blob = new Blob([gltfData], { type: 'application/json' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${fileName}.gltf`;
        link.click();
      } else {
        console.error('GLTFExporter output for GLTF was not an object.');
      }
    },
    (error) => {
      console.error('An error occurred during GLTF export:', error);
    },
    { binary: false } // Export as JSON GLTF
  );
};

interface ModelExporterProps {
  name: string;
}


// usage exportGLB_${name}();
export const ModelExporter: FunctionComponent<PropsWithChildren<ModelExporterProps>> = ({name, children}) => {
  const groupRef = useRef<Group>(null);

  // const handleExport = () => {
  //   if (groupRef.current) {
  //     exportGLB(groupRef.current, 'rollerShutters.glb');
  //   }
  // };

  useEffect(() => {
    // Attach the export function to the window object
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window[`exportGLB_${name}`] = () => {
      if (groupRef.current) {
        exportGLB(groupRef.current, `${name}`);
      } else {
        console.warn('No group reference available for export.');
      }
    };

    // Cleanup the window object on unmount
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete window[`exportGLB_${name}`];
    };
  }, [name]);

  return (
    <group ref={groupRef}>
      {children}
    </group>
  )
  // return (
  //   <div>
  //     <Canvas>
  //       <group ref={groupRef}>
  //         {children}
  //       </group>
  //     </Canvas>
  //     <button onClick={handleExport}>Export as GLB</button>
  //   </div>
  // );
};

