import {createAction} from "@reduxjs/toolkit";

export const updateSelectedRoofTrimAction = createAction<string>('updateSelectedRoofTrimAction');


interface updateSelectedUniqueGroupSectionFragmentActionProps {
  id: string
  deselect?: boolean;
}

export const updateSelectedUniqueGroupSectionFragmentAction = createAction<updateSelectedUniqueGroupSectionFragmentActionProps>('updateSelectedUniqueGroupSectionFragmentAction');
