import {OrderState} from "./State";
import {updateSelectedUniqueGroupSectionFragmentAction, updateSelectedRoofTrimAction} from "./SectionFragmentActions";
import {getSouthFaceFromOrder} from "./Util";
import produce from "immer";
import {SectionFragmentType} from "@hec/api-dtos";


export const updateSelectedRoofTrimReducer = (state: OrderState, action: ReturnType<typeof updateSelectedRoofTrimAction>) => {
  return produce(state, (draft) => {
    return;

  });
}
export const updateSelectedUniqueGroupSectionFragmentReducer = (state: OrderState, action: ReturnType<typeof updateSelectedUniqueGroupSectionFragmentAction>) => {
  return produce(state, (draft) => {
    return;

  });
}
