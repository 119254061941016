import {FrameDto, FrameOverlaysDto, RoofTrimDto, SectionFragmentDto, SectionFragmentType} from "@hec/api-dtos";
import {DormerRootState} from "../DormerRootState";

export const getSelectableSectionFragmentsSelector = (state: DormerRootState): SectionFragmentDto[] => {
  return state.selectableSectionFragments.selectableSectionFragments;
}

export const getSelectableFramesSelector = (state: DormerRootState): FrameDto[] => {
  return getSelectableSectionFragmentsSelector(state).filter(x=> x.sectionFragmentType === SectionFragmentType.Frame) as FrameDto[];
}

export const getDefaultFrameSelector = (state: DormerRootState): FrameDto | null => {
  if (state.selectableSectionFragments.selectableSectionFragments !== null) {
    return getSelectableFramesSelector(state)
      .filter((frame: FrameDto) => frame.isDefaultForTemplate)[0];
  }
  return null;
}

export const getSelectableRoofTrimSelector = (state: DormerRootState): RoofTrimDto[] => {
  return getSelectableSectionFragmentsSelector(state).filter(x=> x.sectionFragmentType === SectionFragmentType.RoofTrim && x.isUserSelectable === true) as FrameDto[];
}

export const getSelectableFrameOverlaySelector = (state: DormerRootState): FrameOverlaysDto[] => {
  return getSelectableSectionFragmentsSelector(state).filter(x=> x.sectionFragmentType === SectionFragmentType.FrameOverlay && x.isUserSelectable === true) as FrameDto[];
}
