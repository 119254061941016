import { Product, ProductWithDrawableObjectWithSpecification } from '../';

export function IsProductWithTheSameSpecifications<S, T extends ProductWithDrawableObjectWithSpecification<S>>
  (specifications: S) {
    return function(product: Product): product is T {
      const specificationKeys = Object.keys(specifications as any);
      const hasNonExistentKeys = specificationKeys.filter((key) => {
        return !Object.keys((product as T).drawableObject.specification as any).includes(key);
      }).length > 0;
      if (hasNonExistentKeys) return false;

      const hasNonExistentValues = specificationKeys
        .filter((key) => {
          return (product as T).drawableObject.specification[key as keyof S] !== specifications[key as keyof S];
        }).length > 0

      return !hasNonExistentValues;
    }
  }
