import * as THREE from "three";
import {fromMmToRenderingMeters} from "@hec/core";

export const createRollerShutterShape = () => {
  const shape = new THREE.Shape();

  const height = fromMmToRenderingMeters(41);

  // Define the points for the custom roller shutter shape

  const heightLower = fromMmToRenderingMeters(2);
  const middle = fromMmToRenderingMeters(3);

  shape.moveTo(middle + 0, 0);
  shape.lineTo(middle + 0, -heightLower);
  shape.lineTo(middle + fromMmToRenderingMeters(1), -heightLower)
  shape.lineTo(middle + fromMmToRenderingMeters(1), 0)
  shape.lineTo(middle + 0, 0)

  // shape.moveTo(0, 0);
  shape.lineTo(fromMmToRenderingMeters(8.5), 0);

  // Create the top curve based on the profile in the image
  shape.bezierCurveTo(
    fromMmToRenderingMeters(5.5),
    fromMmToRenderingMeters(20.5),
    fromMmToRenderingMeters(5.5),
    fromMmToRenderingMeters(20.5),
    fromMmToRenderingMeters(8.5),
    fromMmToRenderingMeters(41)
  );

  shape.lineTo(0, height);

  // Create the bottom curve based on the profile in the image
  shape.bezierCurveTo(
    fromMmToRenderingMeters(3),
    fromMmToRenderingMeters(20),
    fromMmToRenderingMeters(3),
    fromMmToRenderingMeters(20),
    0,
    0
  );

  return shape;
};
