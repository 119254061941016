import {OrderState} from "./State";
import produce from "immer";
import {getConfiguredFramesFromOrder, getSouthFaceFromOrder} from "./Util";
import {
  addNewFrameAction,
  DormerColorType,
  frameTemplateSelectedAction,
  removeFrameAction,
  replaceOrderSectionFragmentsAction,
  swapFrameOrderAction,
  updateFrameSizeAction,
  UpdateSectionFragmentDormerColorActionProps,
  updateSectionFragmentDormerColorsAction
} from "./FrameActions";
import {SectionFragmentDto, SectionFragmentType} from "@hec/api-dtos";

export const addNewFrameReducer = (state: OrderState, action: ReturnType<typeof addNewFrameAction>) => {
  return produce(state, (draft) => {
    if (!draft.order) {
      return;
    }
    const southFace = getSouthFaceFromOrder(draft.order);
    if (!southFace) {
      return;
    }
    draft.isDirty = true;

    // This assumes new frames always have the highest sort value.
    southFace.sectionFragments.push(action.payload);
  });
}
export const removeFrameReducer = (state: OrderState, action: ReturnType<typeof removeFrameAction>) => {
  return produce(state, (draft) => {
    if (!draft.order) {
      return;
    }
    const southFace = getSouthFaceFromOrder(draft.order);
    if (!southFace) {
      return;
    }

    // Remove frame
    const frameIndex = southFace.sectionFragments.findIndex(frame => frame.sectionFragmentType === SectionFragmentType.Frame && frame.sort === action.payload.sort);
    southFace.sectionFragments.splice(frameIndex, 1);

    // Update the sort properties of the remaining frames
    getConfiguredFramesFromOrder(draft.order)
      .forEach((frame, index) => {
        frame.sort = index;
      });
  });
}

export const swapFrameOrderReducer = (state: OrderState, action: ReturnType<typeof swapFrameOrderAction>) => {
  return produce(state, (draft) => {
    if (!draft.order) {
      return;
    }

    const frames = getConfiguredFramesFromOrder(draft.order);

    const frame1 = frames.find(frame => frame.sort === action.payload.sort1)!;
    const frame2 = frames.find(frame => frame.sort === action.payload.sort2)!;
    frame1.sort = action.payload.sort2;
    frame2.sort = action.payload.sort1;


    draft.order.faceConfiguration.faces.forEach((f) => {
      f.sectionFragments = f.sectionFragments
        .sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0));
    })
  });
}


export const updateFrameSizeReducer = (state: OrderState, action: ReturnType<typeof updateFrameSizeAction>) => {
  return produce(state, (draft) => {
    if (!draft.order) {
      return;
    }

    const frames = getConfiguredFramesFromOrder(draft.order);
    const frame = frames.find(frame => frame.sort === action.payload.sort)!;

    frame.width = action.payload.width;
    frame.height = action.payload.height;
  });
}


export const updateSectionFragmentDormerColorsReducer = (state: OrderState, action: ReturnType<typeof updateSectionFragmentDormerColorsAction>) => {
  return produce(state, (draft) => {
    if (!draft.order) {
      return;
    }


    const sectionFragments = getSouthFaceFromOrder(draft.order)?.sectionFragments;


    const noIdErr = (sectionFragments ?? []).filter(x=> x.id == null && x.tempId == null);

    if(noIdErr.length > 0) {
      console.log(noIdErr);
      const asdfsd = noIdErr;
    }

    if (sectionFragments) {
      action.payload.changes.forEach((requestedMutation: UpdateSectionFragmentDormerColorActionProps) => {
        const sectionFragment = sectionFragments.find(x => (x.id ?? x.tempId) === requestedMutation.id);

        if (sectionFragment) {


          if(requestedMutation.dormerColorType === DormerColorType.sideRods) {
            if (sectionFragment.configuredDormerColorSideRodId !== requestedMutation.color.id) {
              draft.isDirty = true;
            }

            sectionFragment.configuredDormerColorSideRods = requestedMutation.color;
             sectionFragment.configuredDormerColorSideRodId = requestedMutation.color.id;
          } else {
            if (sectionFragment.configuredDormerColorId !== requestedMutation.color.id) {
              draft.isDirty = true;
            }

            sectionFragment.configuredDormerColor = requestedMutation.color;
            sectionFragment.configuredDormerColorId = requestedMutation.color.id;
          }
        }
      });
    }
  });
}

export const replaceOrderSectionFragmentsReducer = (state: OrderState, action: ReturnType<typeof replaceOrderSectionFragmentsAction>) => {
  return produce(state, (draft) => {
    if (!draft.order) {
      return;
    }
    draft.isDirty = true;

    const southFace = getSouthFaceFromOrder(draft.order);

    const sectionFragments = southFace?.sectionFragments;

    if (!sectionFragments) {
      return;
    }

    const newSectionFragments: SectionFragmentDto[] = [];

    action.payload.sectionFragments.forEach((requestedMutation) => {
      if (!requestedMutation.dirty) {
        newSectionFragments.push(requestedMutation)
        return;
      }
      let existing = sectionFragments?.find(x => (x.id ?? x.tempId) === (requestedMutation.id ?? requestedMutation.tempId));

      if (existing) {
        requestedMutation.dirty = false;
        existing = {...requestedMutation} as SectionFragmentDto;
        newSectionFragments.push(existing);
      } else {
        newSectionFragments?.push({...requestedMutation} as SectionFragmentDto)
      }
    });

    newSectionFragments.sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0));

    const newSectionFragmentIds = newSectionFragments.filter(x=> x.id != null).map(x=> x.id);

    const example = newSectionFragments.filter(x=> x.name === "Inzet hor")

    const duplicatIdSectionFragments = newSectionFragments.filter(x=> newSectionFragmentIds.filter(id => x.id == id).length > 1);

    if(duplicatIdSectionFragments.length > 0) {
      console.error("DUPLICATE ID REPLACEMENT ERR!");
    }

    getSouthFaceFromOrder(draft.order)!.sectionFragments! = newSectionFragments
  });
}
export const frameTemplateSelectedReducer = (state: OrderState, action: ReturnType<typeof frameTemplateSelectedAction>) => {
  return produce(state, (draft) => {
    if (!draft.order) {
      return;
    }
    draft.isDirty = true;

    return;
  });
}

