import React, {FunctionComponent, memo, useEffect, useMemo, useRef} from "react";
import {FaceDto, RoofTrimVariation, SectionFragmentType} from "@hec/api-dtos";
import {CdnUrlHelper, fromMmToRenderingMeters, getTotalWidth, RoofOverhangViewModel} from "@hec/core";
import {CubeTextureLoader, CylinderGeometry, Matrix4, MeshPhongMaterial, MultiplyOperation, Vector3} from "three";
import {degToRad} from "three/src/math/MathUtils";
import {Conditional, ZincFlatCorner, ZincFlatStraight} from "@hec/components/v2";
import {DEFAULT_DORMER_DEPTH} from "../../Dormer3dRenderer";
import {RoofTrimCharlotte} from "./RoofTrimCharlotte";
import {DormerVisualizationSettings} from "@hec/models";
import {RoofTrimClaire} from "./RoofTrimClaire";
import {RoofTrimZoey} from "./RoofTrimZoey";
import {RoofTrimJudith} from "./RoofTrimJudith";

export interface RoofTrimProps {
  southFace: FaceDto;
  roofOverhang: RoofOverhangViewModel;
  visualizationSettings: DormerVisualizationSettings;
  xOffsetStubbed: number;
  yOffsetStubbed: number;
}


const cdnPublicRoot = CdnUrlHelper.getPublicRoot();
const cubeTextureLoader = new CubeTextureLoader();
const envTexture = cubeTextureLoader.load([
  cdnPublicRoot + "/px.jpg",
  cdnPublicRoot + "/nx.jpg",
  cdnPublicRoot + "/py.jpg",
  cdnPublicRoot + "/ny.jpg",
  cdnPublicRoot + "/pz.jpg",
  cdnPublicRoot + "/nz.jpg"
]);

const MemoizedZincFlatStraight = memo(ZincFlatStraight);
const cornerCompensation = 0.232;
const xAxisDiff = 0.1;
const roofTrimDepth = 0.110;

export const RoofTrim: FunctionComponent<RoofTrimProps> = ({southFace, roofOverhang,visualizationSettings, xOffsetStubbed, yOffsetStubbed}: RoofTrimProps) => {
  const southSectionFragments = southFace.sectionFragments;
  const stubbedSectionFragment = southSectionFragments.find(x => x.sectionFragmentType === SectionFragmentType.Stubbed);
  const roofTrim = southSectionFragments.find(x => x.sectionFragmentType === SectionFragmentType.RoofTrim);



  if(roofTrim?.roofTrimVariation === RoofTrimVariation.Charlotte) {
    return <group>
      <RoofTrimCharlotte
        southFace={southFace}
        roofOverhang={roofOverhang}
        visualizationSettings={visualizationSettings}
        xOffsetStubbed={xOffsetStubbed}
        yOffsetStubbed={yOffsetStubbed}
      />
    </group>
  }
  else if(roofTrim?.roofTrimVariation === RoofTrimVariation.Claire) {
    return <RoofTrimClaire
      southFace={southFace}
      roofOverhang={roofOverhang}
      visualizationSettings={visualizationSettings}
      xOffsetStubbed={xOffsetStubbed}
      yOffsetStubbed={yOffsetStubbed}
    />
  }
  else if(roofTrim?.roofTrimVariation === RoofTrimVariation.Judith) {
    return <RoofTrimJudith
      southFace={southFace}
      roofOverhang={roofOverhang}
      visualizationSettings={visualizationSettings}
      xOffsetStubbed={xOffsetStubbed}
      yOffsetStubbed={yOffsetStubbed}
    />
  }
  else if(roofTrim?.roofTrimVariation === RoofTrimVariation.Zoey) {
    return <RoofTrimZoey
      southFace={southFace}
      roofOverhang={roofOverhang}
      visualizationSettings={visualizationSettings}
      xOffsetStubbed={xOffsetStubbed}
      yOffsetStubbed={yOffsetStubbed}
    />
  }

  return <RoofTrimZoey
    southFace={southFace}
    roofOverhang={roofOverhang}
    visualizationSettings={visualizationSettings}
    xOffsetStubbed={xOffsetStubbed}
    yOffsetStubbed={yOffsetStubbed}
  />
    // return <group>
    //   <RoofTrimCharlotte
    //     southFace={southFace}
    //     roofOverhang={roofOverhang}
    //     visualizationSettings={visualizationSettings}
    //     xOffsetStubbed={xOffsetStubbed}
    //     yOffsetStubbed={yOffsetStubbed}
    //   />
    // </group>
}
