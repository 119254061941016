import {SectionFragmentDto, SectionFragmentType} from "@hec/api-dtos";
import React, {FunctionComponent, useEffect, useState} from "react";
import {OptionItem, OptionList} from "../OptionList";

interface SelectableSectionFragmentSelectorProps {
  selectedSectionFragmentId: string | null,
  sectionFragments: SectionFragmentDto[],
  sectionFragmentType?: SectionFragmentType,
  onSelectionChanged: (sectionFragment: SectionFragmentDto) => void;
  allowUnset?: boolean
}

export const SelectableSectionFragmentSelector: FunctionComponent<SelectableSectionFragmentSelectorProps> = ({
                                                                                                               sectionFragments,
                                                                                                               selectedSectionFragmentId,
                                                                                                               sectionFragmentType,
                                                                                                               onSelectionChanged,
                                                                                                               allowUnset
                                                                                                             }: SelectableSectionFragmentSelectorProps) => {
  const [selection, setSelection] = useState<string | null>(null)

  return <>
    <OptionList
      items={sectionFragments.map(sf => ({
        id: sf.id,
        name: sf.name,
      } as OptionItem))}
      allowUnset={allowUnset === true}
      selectedItem={selection ?? selectedSectionFragmentId}
      setSelectedItem={(id) => {
        setSelection(id);
        onSelectionChanged(sectionFragments.find(x=> x.id === id)!);
      }}
    />
  </>
}
