import {OrderDto, SectionFragmentDto  } from "@hec/api-dtos";
import {getSouthFaceFromOrder} from "./GetSouthFaceFromOrder";
import _ from "lodash";

export const getSectionFragmentsFromOrder = (order: OrderDto): SectionFragmentDto[] => {
  const sfs = getSectionFragmentsFromOrderTracked(order);

  // TODO: Make sure this is pre sorted in any situation. Debug with canary
  const sectionFragments = (_.clone(sfs) as SectionFragmentDto[])
    .sort((prev, next) => prev.sort! - next.sort!);
  return (sectionFragments as SectionFragmentDto[]);
}


export const getSectionFragmentsFromOrderTracked = (order: OrderDto): SectionFragmentDto[] => {
  const southFace = getSouthFaceFromOrder(order);
  if (!southFace) {
    return [];
  }

  return (southFace.sectionFragments) as SectionFragmentDto[];
}
