import React, {FunctionComponent} from "react";
import {
  FaceDto, ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType
} from "@hec/api-dtos";
import {degreesToRadians, getTotalWidth} from "@hec/core";
import {DormerVisualizationSettings} from "@hec/models";
import {CubeTextureLoader, Shape, Euler, Vector3} from "three";


export const CSG_BG_HEIGHT = 50;
export const CSG_BG_DEPTH = CSG_BG_HEIGHT;

export interface CsgSubtractorProps {
  visualizationSettings: DormerVisualizationSettings,
  southFace: FaceDto
}

export const CsgSubtractor: FunctionComponent<CsgSubtractorProps> = ({
                                                                       visualizationSettings,
                                                                       southFace
                                                                     }: CsgSubtractorProps) => {
  const slopeDegrees = visualizationSettings?.visualizationType === ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType.Slanted ? 45 : 25;
  const rotationAngleFromVertical = 90 - slopeDegrees;
  const rotationAngle = -degreesToRadians(rotationAngleFromVertical);

  const totalWidth = getTotalWidth(southFace);


  const csgBgWidth = totalWidth + 2;
  const CSG_BG_HEIGHT = 50;
  const CSG_BG_DEPTH = CSG_BG_HEIGHT;


  return <group>
    <CsgSubtractorGeom
      southFace={southFace}

      visualizationSettings={visualizationSettings}
    />
    {/*<mesh*/}
    {/*  rotation={[rotationAngle, 0, 0]}*/}
    {/*>*/}
    {/*  <mesh*/}
    {/*    position={[0, CSG_BG_HEIGHT / 2, -CSG_BG_DEPTH / 2]}*/}
    {/*  >*/}
    {/*    <boxGeometry*/}

    {/*      args={[csgBgWidth, CSG_BG_HEIGHT, CSG_BG_DEPTH]}/>*/}
    {/*    <meshStandardMaterial color="green" opacity={0.1}/>*/}
    {/*  </mesh>*/}
    {/*</mesh>*/}
  </group>
}


export const getCsgSubtractorRotationEuler = (visualizationSettings: DormerVisualizationSettings, southFace: FaceDto): Euler => {
  const slopeDegrees = visualizationSettings?.visualizationType === ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType.Slanted ? 45 : 25;
  const rotationAngleFromVertical = 90 - slopeDegrees;
  const rotationAngle = -degreesToRadians(rotationAngleFromVertical);

  return new Euler(rotationAngle, 0, 0);
}
export const getCsgSubtractorPositionEuler = (visualizationSettings: DormerVisualizationSettings, southFace: FaceDto): Vector3 => {
  return new Vector3(0, CSG_BG_HEIGHT / 2, -CSG_BG_DEPTH / 2);
}

export const CsgSubtractorGeom: FunctionComponent<CsgSubtractorProps> = ({
                                                                           visualizationSettings,
                                                                           southFace
                                                                         }: CsgSubtractorProps) => {
  return <mesh
    rotation={getCsgSubtractorRotationEuler(visualizationSettings, southFace)}
  >
    <mesh
      position={getCsgSubtractorPositionEuler(visualizationSettings, southFace)}
    >
      <CsgSubtractorBox visualizationSettings={visualizationSettings} southFace={southFace}/>
      <meshStandardMaterial color="red" opacity={0.1}/>
    </mesh>
  </mesh>
}


export const CsgSubtractorBox: FunctionComponent<CsgSubtractorProps> = ({southFace}: CsgSubtractorProps) => {
  const totalWidth = getTotalWidth(southFace);
  const csgBgWidth = totalWidth + 2;

  return <boxGeometry
    args={[csgBgWidth, CSG_BG_HEIGHT, CSG_BG_DEPTH]}/>
}
