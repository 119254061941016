import {FunctionComponent} from "react";
import {FaceDto} from "@hec/api-dtos";
import {FrameGroupsRenderer} from "./FrameGroupsRenderer";
import {Base3dProps} from "../Base3dProps";
import {FrameOverlayRenderer} from "./FrameOverlayRenderer";

export interface SectionFragmentsRendererProps extends Base3dProps {
  face:FaceDto;
}

export const SectionFragmentsRenderer: FunctionComponent<SectionFragmentsRendererProps> = ({face, visualizationSettings}: SectionFragmentsRendererProps) => {

  return <>
    <FrameGroupsRenderer
      face={face}
      visualizationSettings={visualizationSettings}
    />
    {/*<FrameOverlayRenderer*/}
    {/*  face={face}*/}
    {/*  visualizationSettings={visualizationSettings}*/}
    {/*/>*/}
  </>
}
