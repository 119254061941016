import React, {FunctionComponent, useEffect, useState} from "react";
import styles from "../frames/frames.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {DisplaySurveyQuestions, SidebarFooter} from "@hec/components/v2";
import {RoutePaths} from "../../routing";
import {SurveyAnswerViewModel, SurveyQuestionType, TranslationKeys} from "@hec/models";
import {
  getClientInformationSelector,
  getIsOrganisationSelector,
  getOrderSelector,
  getSelectedConfigurationTemplateSelector,
  getSurveyQuestionsAction,
  getSurveyQuestionsAreLoadedSelector,
  getSurveyQuestionsSelector, holdSurveyAnswersAction,
  putSurveyAnswersAction,
} from "@hec/dal";
import {CANVAS_THREE_ID, ExtraInformationForKeyRenderer} from "../../components";

export const CustomerSurveySideBarContent: FunctionComponent = () => {
  const [answers, setAnswers] = useState<SurveyAnswerViewModel[]>();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clientInformation = useSelector(getClientInformationSelector);
  const surveyQuestions = useSelector(getSurveyQuestionsSelector);
  const order = useSelector(getOrderSelector);
  const isOrganisation = useSelector(getIsOrganisationSelector);

  const loaded = useSelector(getSurveyQuestionsAreLoadedSelector);


  let nextRoutePath = '';
  let nextRouteCTA = '';

  if (isOrganisation) {
    nextRoutePath = RoutePaths.TechnicalPath;
    nextRouteCTA = t(TranslationKeys.dormer.pages.technicalPage.cta);
  } else {
    nextRoutePath = RoutePaths.SummaryPath;
    nextRouteCTA = t(TranslationKeys.dormer.pages.summaryPage.cta);
  }


  const selectedConfigurationTemplate = useSelector(getSelectedConfigurationTemplateSelector);

  useEffect(() => {
    if (!loaded && selectedConfigurationTemplate?.id != null) {
      dispatch(getSurveyQuestionsAction({
        configurationTemplateId: selectedConfigurationTemplate?.id
      }));
    }
  }, [selectedConfigurationTemplate, loaded, dispatch]);


  useEffect(() => {
    const desired3DFiles = surveyQuestions.filter(x=> x.forPro && x.surveyQuestionType === SurveyQuestionType.ImageThreeDimensions);
    if(desired3DFiles.length > 0 && order?.id != null) {
      try {
        const canvas: any = (document!.getElementById(CANVAS_THREE_ID) as unknown as any).children[0].children[0];

        const base64Image = canvas.toDataURL("image/png");

        console.log(base64Image);

        const desired3DFilesAlphabetically = desired3DFiles.sort((a, b) => a.label.localeCompare(b.label));

        const toUploadFor = desired3DFilesAlphabetically[0];

        const imageFileList = new DataTransfer();
        imageFileList.items.add(new File([Uint8Array.from(atob(base64Image.split(",")[1]), c => c.charCodeAt(0))], "screenshot.png", { type: "image/png" }));
        const fileList: FileList = imageFileList.files;


        const holdingAnswer = [new SurveyAnswerViewModel(
          toUploadFor.id,
          toUploadFor.surveyQuestionType,
          fileList,
        )];

        dispatch(holdSurveyAnswersAction( { orderId: order.id, surveyAnswerViewModels: holdingAnswer}));

      } catch (e) {
        console.error('error rendering desired 3d base64 screenshot', e);
      }
    }
  }, [dispatch, surveyQuestions, order]);


  if (!loaded || clientInformation === null) {
    return <>...</>;
  }

  return (
    <>
      <div className={styles.sections}>
        <ExtraInformationForKeyRenderer
          extraInformationKey={'CustomerSurveyExtraInformation'}
        />
        <DisplaySurveyQuestions
          surveyQuestionViewModels={surveyQuestions.filter(e => !e.forPro)}
          surveyAnswerViewModelsChanged={setAnswers}
        />
      </div>
      <SidebarFooter
        prevRoutePath={RoutePaths.ExtrasPath}
        nextRoutePath={nextRoutePath}
        nextRouteCTA={nextRouteCTA}
        clientInformation={clientInformation}
        onGoNext={() => {
          const putMeDaddy = (answers ?? []).map(e => new SurveyAnswerViewModel(
            e.questionId,
            e.surveyQuestionType,
            e.getNormalizedValueFromForm(),
          ));

          dispatch(putSurveyAnswersAction( { orderId: order!.id, surveyAnswerViewModels: putMeDaddy}));
        }}
      />
    </>
  );
};
