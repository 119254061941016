import React, {FunctionComponent, useMemo} from "react";
import * as THREE from "three";
import {fromMmToRenderingMeters} from "@hec/core";
import {DoubleSide, MeshStandardMaterial, TextureLoader} from "three";
import {createRollerShutterShape} from "../CreateRollerShutterShape";
import {FrameOverlayRollerShuttersProps} from "./FrameOverlayRollerShuttersProps";
import {GuideRailRendererProps} from "./FrameOverlayRollerShuttersRenderer";
import {Cylinder} from "@react-three/drei";
import {OPENING_FRAME_ROD} from "../../../../../constants";
import {GroupHelper, WrappingUtility} from "@hec/components/v2";
import {distanceFromZeroZ, screenTexture} from "./index";
import {useLoader} from "@react-three/fiber";
import {DormerColorViewModel} from "@hec/api-dtos";
import {DormerColorMaterial} from "../../DormerColorMaterial";

const spaceBetweenLamelHeight = fromMmToRenderingMeters(5);

export const FrameOverlayRollerBlindsRoofWindowRenderer: FunctionComponent<FrameOverlayRollerShuttersProps> =
  ({startCoordinateX, endCoordinateX, width, totalHeight, yOffSetFrames, primaryColor, secondaryColor}: FrameOverlayRollerShuttersProps) => {

    const materialSiderod = <DormerColorMaterial
      dormerColor={secondaryColor ?? primaryColor}
    />

    const frameRabbetSideThickness = fromMmToRenderingMeters(OPENING_FRAME_ROD);
    // Naar spec: https://www.rolluiken33.nl/alutech-basisline-rolluik.html

    const renderingWidth = fromMmToRenderingMeters(width);
    // We are supposed to cover this distance with rolling parts only,
    // so the rails dont count over the renderingWidth hence we need to add the rails distance.
    const guideRailWidthMM = 22;
    const guideRailWidth = fromMmToRenderingMeters(guideRailWidthMM);
    // renderingWidth += guideRailWidth * 2;

    const roundingDistance = fromMmToRenderingMeters(50);
    const roundingDistanceTop = fromMmToRenderingMeters(80);
    const renderingHeightMM = totalHeight - OPENING_FRAME_ROD * 3;
    const renderingHeight = fromMmToRenderingMeters(renderingHeightMM);
    const underBarDepth = frameRabbetSideThickness / 2;
    const topBarHeight = renderingHeight - (roundingDistanceTop);

    const lamelHeightMm = 40;
    const lamelHeight = fromMmToRenderingMeters(lamelHeightMm)
    const lamelDepth = fromMmToRenderingMeters(9);

    const amountOfLammellen = Math.round(renderingHeightMM / lamelHeightMm / 3);

    const gapSpace = fromMmToRenderingMeters(15);
    const betweenGapSpace = fromMmToRenderingMeters(17);
    const gapTotal = gapSpace + betweenGapSpace;

    const bottomBarDepth = fromMmToRenderingMeters(6);
    const bottomBarHeight = fromMmToRenderingMeters(50);

    const gapCreationRodHeight = amountOfLammellen * (lamelHeight + spaceBetweenLamelHeight);

    const color = materialSiderod


    const repeatAmount = 6;


    const textureMap =  useLoader(TextureLoader, screenTexture);


    const wrapped = WrappingUtility.wrapTexture(textureMap, renderingWidth * repeatAmount, gapCreationRodHeight * repeatAmount)


    const materialPrimary = <meshStandardMaterial
      attach={undefined}
      color={`#${primaryColor?.colorCode}`}
      roughness={1}
      metalness={0.01}
      side={DoubleSide}
      map={wrapped}
    />



    const sideRod = (<group>
        <group position={[-guideRailWidth, 0, frameRabbetSideThickness]}>
          <mesh position={[guideRailWidth / 2, renderingHeight / 2, 0 - underBarDepth / 2]} castShadow={true} receiveShadow={true}>
            <boxGeometry
              args={[guideRailWidth, renderingHeight, underBarDepth]}
            />
            {color}
          </mesh>
        </group>
        <group
          position={[-guideRailWidth, roundingDistance, frameRabbetSideThickness + roundingDistance]}>
          <mesh position={[guideRailWidth / 2, topBarHeight / 2, 0 - roundingDistance / 2]} castShadow={true} receiveShadow={true}>
            <boxGeometry
              args={[guideRailWidth, topBarHeight, roundingDistance]}
            />
            {color}
          </mesh>
        </group>
        <group position={[-guideRailWidth, 0, frameRabbetSideThickness]}>
          <mesh rotation={[0, 0, Math.PI / 2]} position={[guideRailWidth / 2, roundingDistance, 0]} castShadow={true} receiveShadow={true}>
            <cylinderGeometry
              args={[
                // radiusTop: 1,
                roundingDistance,
                // radiusBottom: 1,
                roundingDistance,
                // height: 1,
                guideRailWidth,
                // radialSegments: 6,
                6,
                // heightSegments: 1,
                1,
                // openEnded: false,
                false,
                // thetaStart: 0,
                -Math.PI / 2,
                // thetaLength: Math.PI
                Math.PI / 2
              ]}
            />
            {color}
          </mesh>
        </group>
      </group>
    );



    const middleRodWidth = renderingWidth + guideRailWidth *2;

    return <group position={[fromMmToRenderingMeters(startCoordinateX), yOffSetFrames, 0]}>
      <group>


        <group key={`siderod-west`}>
          {sideRod}
        </group>

        <group
          key={'middle-rod'}
          position={[-middleRodWidth/2 + guideRailWidth, renderingHeight - roundingDistanceTop * 2, frameRabbetSideThickness]}>
          <mesh rotation={[0, 0, Math.PI / 2]} position={[renderingWidth, roundingDistanceTop, 0]} castShadow={true} receiveShadow={true}>
            <cylinderGeometry
              args={[
                // radiusTop: 1,
                roundingDistanceTop,
                // radiusBottom: 1,
                roundingDistanceTop,
                // height: 1,
                middleRodWidth,
                // radialSegments: 6,
                4,
                // heightSegments: 1,
                1,
                // openEnded: false,
                false,
                // thetaStart: 0,
                -Math.PI / 2,
                // thetaLength: Math.PI
                Math.PI
              ]}
            />
            {color}
          </mesh>
        </group>

        <group key={`siderod-east`} position={[renderingWidth + guideRailWidth, 0, 0]}>
          {sideRod}
        </group>


      </group>
      <group position={[(renderingWidth / 2), 0, frameRabbetSideThickness + roundingDistance]}>
        <group key={'lamellen'} position={[0, (renderingHeight - gapCreationRodHeight) - roundingDistanceTop, 0]}>
          <group key={'rows'}>
            <mesh position={[0, gapCreationRodHeight / 2, fromMmToRenderingMeters(-5)]} castShadow={true} receiveShadow={true}>
              <planeGeometry args={[renderingWidth, gapCreationRodHeight]}/>
              {materialPrimary}
            </mesh>
            {/*{Array.from({length: amountOfLammellen}).map((_, index) =>*/}
            {/*  (<group*/}
            {/*    position={[-renderingWidth / 2, index * (lamelHeight + spaceBetweenLamelHeight), 0]}*/}
            {/*    rotation={[0, Math.PI / 2, 0]}>*/}
            {/*    <mesh>*/}
            {/*      {bar}*/}
            {/*      {color}*/}
            {/*    </mesh>*/}
            {/*  </group>))*/}
            {/*}*/}
          </group>
          {/*<group*/}
          {/*  position={[-renderingWidth / 2, gapCreationRodHeight / 2 + -spaceBetweenLamelHeight, -lamelDepth / 2 + fromMmToRenderingMeters(1) / 2]}>*/}
          {/*  {Array.from({length: gridRepeatForGapX}).map((_, index) =>*/}
          {/*    (<group>*/}
          {/*      <mesh position={[index * gapTotal + (betweenGapSpace / 2), 0, 0]}>*/}
          {/*        <boxGeometry args={[betweenGapSpace, gapCreationRodHeight, fromMmToRenderingMeters(2)]}/>*/}
          {/*        {color}*/}
          {/*      </mesh>*/}
          {/*    </group>))*/}
          {/*  }*/}
          {/*</group>*/}
          <mesh key={`bottomBar`}
                position={[0, -(bottomBarHeight / 2), -bottomBarDepth / 2]} castShadow={true} receiveShadow={true}>
            <boxGeometry
              args={[renderingWidth, bottomBarHeight, bottomBarDepth]}
            />
            {color}
          </mesh>
        </group>
        {/*<group*/}
        {/*  key={'siderod-left'}*/}
        {/*  rotation={[Math.PI / 2, 0, 0]}*/}
        {/*  position={[-renderingWidth / 2, renderingHeight, -fromMmToRenderingMeters(10)]}*/}
        {/*>*/}
        {/*  {sideRod}*/}
        {/*</group>*/}
        {/*<group*/}
        {/*  key={'siderod-right'}*/}
        {/*  rotation={[Math.PI / 2, Math.PI, 0]}*/}
        {/*  position={[renderingWidth / 2, 0, -fromMmToRenderingMeters(10)]}*/}
        {/*>*/}
        {/*  {sideRod}*/}
        {/*</group>*/}
      </group>
    </group>
  }
