import {
  DormerMaterialViewModel,
  FrameOverlaysType,
  FrameVariation, RoofTrimVariation,
  SectionFragmentSide,
  SectionFragmentType
} from "@hec/api-dtos";
import _ from "lodash";

export const makeInspectable = (b: any): any => {
  const a = _.cloneDeep(b);

  const processDm = (obj:DormerMaterialViewModel) => {
    return {
      id: obj?.id,
      dormerColors: obj?.dormerColors.map(x=> { return {id: x.id}}),
    };
  }

  const processObject = (obj: any) => {
    if(!obj) {
      return obj;
    }
    if (obj?.configuredDormerColor?.id) {
      obj.configuredDormerColorId = obj.configuredDormerColor.id ?? obj.configuredDormerColorId;
      obj.configuredDormerColor = { id: obj.configuredDormerColorId};
    }

    if (obj?.configuredDormerColorSideRods?.id) {
      obj.configuredDormerColorSideRodsId = obj.configuredDormerColorSideRods.id ?? obj.configuredDormerColorSideRodsId;
      obj.configuredDormerColorSideRods = { id: obj.configuredDormerColorSideRods};
    }

    if(obj.copiedFrom?.id) {
      obj.copiedFromId = obj.copiedFrom?.id ?? obj.copiedFromId;
      obj.copiedFrom = {id: obj.copiedFromId}
    }


    if(obj.availableDormerMaterials?.length >= 1) {
      obj.availableDormerMaterials = obj.availableDormerMaterials.map((dm: DormerMaterialViewModel) => {
        const result = processDm(dm);
        return result;
      })
    }

    if(obj.availableDormerMaterialsSideRods?.length >= 1) {
      obj.availableDormerMaterialsSideRods = obj.availableDormerMaterialsSideRods.map((dm: DormerMaterialViewModel) => {
        const result = processDm(dm);
        return result;
      })
    }

    if(obj.configuredDormerColorSideRods) {
      obj.configuredDormerColorSideRods = {id: obj.configuredDormerColorId}
    }
    if(obj.sectionFragmentType) {
      obj.sectionFragmentTypeString = SectionFragmentType[obj.sectionFragmentType].toString();
    }
    if(obj.variation) {
      obj.variationString = FrameVariation[obj.variation].toString();
    }
    if(obj.frameOverlaysType) {
      obj.frameOverlaysTypeString = FrameOverlaysType[obj.frameOverlaysType].toString();
    }
    if(obj.cheekSide) {
      obj.cheekSideString = SectionFragmentSide[obj.cheekSide].toString();
    }
    if(obj.roofTrimVariation) {
      obj.roofTrimVariationString = RoofTrimVariation[obj.roofTrimVariation].toString();
    }
    if(obj.splinterSide) {
      obj.splinterSideString = SectionFragmentSide[obj.splinterSide].toString();
    }
    if(obj.stubbedSide) {
      obj.stubbedSideString = SectionFragmentSide[obj.stubbedSide].toString();
    }

    return obj;
  };

  if (Array.isArray(a)) {
    return a.map(item => processObject(item));
  } else if (typeof a === 'object' && a !== null) {
    return processObject(a);
  } else {
    console.warn("Input is neither an object nor an array of objects.");
    return a;
  }
}
