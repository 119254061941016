import createSagaMiddleware from 'redux-saga';
import {applyMiddleware, Middleware, Store} from "redux";
import {configureStore} from '@reduxjs/toolkit';
import dormerSaga from './dormerSaga';
import {DormerPizza} from "./dormerPizza";
import {createLogger} from "redux-logger";
import {AppConfiguration} from "@hec/models";

export class DormerStoreContainer {
  store: Store;

  ConfigureDormerStore = (configuration: AppConfiguration) => {
    const sagaMiddleware = createSagaMiddleware();

    const middlewares: Middleware[] = [sagaMiddleware];

    const enhancer = applyMiddleware(...middlewares);


    const applicationStore = configureStore({
      reducer: DormerPizza(configuration),
      enhancers: [enhancer],
      middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
      })
      //   .concat(createLogger({
      //   predicate: (getState, action) => {
      //     return getState().logging?.settings?.enableReduxLogging ?? false;
      //   },
      // }))
      ,
    })

    sagaMiddleware.run(dormerSaga);

    return applicationStore;
  }

  constructor(configuration: AppConfiguration) {
    this.store = this.ConfigureDormerStore(configuration);
  }
}

// let store: Store = ConfigureDormerStore();
// export type DormerRootState = ReturnType<typeof store.getState>;
// export const DormerStore = store;
