import {
  createStore,
  combineReducers,
  applyMiddleware,
  Middleware, Dispatch, AnyAction,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import watchAll from '../../RootSaga';
import {
  HomeConfigurationReducer,
  HomeConfigurationReducerInitialState,
} from '../Reducers';
import { DevtoolsEnhancer, loggerMiddleware } from '../../Middleware';
import {HomeConfigurationSaga} from "../Sagas/";
import {GenericSaga} from "../../Sagas";
import {ApplicationStateReducer, ApplicationStateReducerInitialState} from "../../Reducers/Application";

export const homeExtensionReducerStates = {
  applicationStateReducer: ApplicationStateReducerInitialState,
  homeConfigurationReducer: HomeConfigurationReducerInitialState,
};

export type HomeExtensionRootState = typeof homeExtensionReducerStates;

export const ConfigureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const combinedReducers = combineReducers({
    applicationStateReducer: ApplicationStateReducer,
    homeConfigurationReducer: HomeConfigurationReducer,
  });

  const middlewares: Middleware[] = [sagaMiddleware, (loggerMiddleware as Middleware<any, any, Dispatch<AnyAction>>)];

  const enhancer = DevtoolsEnhancer(applyMiddleware(...middlewares));

  const applicationStore = createStore(combinedReducers, enhancer);

  sagaMiddleware.run(applicationSagas);

  return applicationStore;
};

function* applicationSagas() {
  yield watchAll([GenericSaga, HomeConfigurationSaga]);
}
