import * as THREE from 'three';
import React, {FunctionComponent, memo, useEffect, useMemo, useRef} from "react";
import {FaceDto, SectionFragmentType, Side} from "@hec/api-dtos";
import {CdnUrlHelper, fromMmToRenderingMeters, getTotalWidth, RoofOverhangViewModel} from "@hec/core";
import {CubeTextureLoader, Shape, Euler, DoubleSide} from "three";
import {DEFAULT_DORMER_DEPTH} from "../../Dormer3dRenderer";
import {Addition, Base, Geometry, Subtraction} from "@react-three/csg";
import {Conditional, GroupHelper} from "@hec/components/v2";
import {
  CSG_BG_DEPTH,
  CSG_BG_HEIGHT,
  CsgSubtractor,
  CsgSubtractorBox,
  CsgSubtractorGeom,
  getCsgSubtractorPositionEuler,
  getCsgSubtractorRotationEuler
} from "../CsgSubtractor";
import {DormerVisualizationSettings} from "@hec/models";
import {ChromeMaterial, DormerColorMaterial} from "../DormerColorMaterial";


export interface RoofTrimJudithProps {
  southFace: FaceDto;
  roofOverhang: RoofOverhangViewModel;
  visualizationSettings: DormerVisualizationSettings;
  xOffsetStubbed: number;
  yOffsetStubbed: number;
}

const cdnPublicRoot = CdnUrlHelper.getPublicRoot();
const cubeTextureLoader = new CubeTextureLoader();
const envTexture = cubeTextureLoader.load([
  cdnPublicRoot + "/px.jpg",
  cdnPublicRoot + "/nx.jpg",
  cdnPublicRoot + "/py.jpg",
  cdnPublicRoot + "/ny.jpg",
  cdnPublicRoot + "/pz.jpg",
  cdnPublicRoot + "/nz.jpg"
]);


export const RoofTrimJudith: FunctionComponent<RoofTrimJudithProps> = ({
                                                                         southFace,
                                                                         roofOverhang,
                                                                         visualizationSettings,
                                                                         xOffsetStubbed,
                                                                         yOffsetStubbed,
                                                                       }: RoofTrimJudithProps) => {

  const southSectionFragments = southFace.sectionFragments;
  const stubbedSectionFragment = southSectionFragments.find(x => x.sectionFragmentType === SectionFragmentType.Stubbed);
  const roofTrim = southSectionFragments.find(x => x.sectionFragmentType === SectionFragmentType.RoofTrim);
  const stubbedHeight = fromMmToRenderingMeters(stubbedSectionFragment?.height ?? -1);

  if (!roofTrim) {
    return <></>
  }

  const height = fromMmToRenderingMeters(22)//roofTrim.height);
  const depth = fromMmToRenderingMeters(100)//roofTrim.width);
  const stubbedWidth = fromMmToRenderingMeters(getTotalWidth(southFace)) + roofOverhang.stubbedWestOverhang + roofOverhang.stubbedEastOverhang;

  const width = stubbedWidth;

  const shape = new Shape();

  const circleRadius = height;
  const flattopDepth = depth - circleRadius;

  shape.moveTo(0, 0);
  shape.lineTo(height, height);
  shape.lineTo(flattopDepth, height);
  shape.lineTo(flattopDepth, 0);
  shape.absarc(flattopDepth,
    circleRadius / 2,
    circleRadius / 2,
    Math.PI / 2,
    Math.PI * 1.5,
    true);

  shape.lineTo(0, 0);


  shape.closePath();


  const extrudeSettingsFront = {
    steps: 1,
    depth: width,
    bevelEnabled: false,
  };
  const extrudeSettingsSide = {
    steps: 1,
    depth: DEFAULT_DORMER_DEPTH,
    bevelEnabled: false,
  };

  return (<group>
      {/*<group key={'rooftrims'} position={[0, stubbedHeight - height / 2, 0]}>*/}
      <mesh key={'rooftrims'} position={[0, stubbedHeight - height / 2, 0]}>
        <RoofTrimLengthJudith
          southFace={southFace}
          roofOverhang={roofOverhang}
          side={Side.South}
          visualizationSettings={visualizationSettings}
          xOffsetStubbed={xOffsetStubbed}
          yOffsetStubbed={yOffsetStubbed}
        />
        <RoofTrimLengthJudith
          southFace={southFace}
          roofOverhang={roofOverhang}
          side={Side.East}
          visualizationSettings={visualizationSettings}
          xOffsetStubbed={xOffsetStubbed}
          yOffsetStubbed={yOffsetStubbed}
        />
        <RoofTrimLengthJudith
          southFace={southFace}
          roofOverhang={roofOverhang}
          side={Side.West}
          visualizationSettings={visualizationSettings}
          xOffsetStubbed={xOffsetStubbed}
          yOffsetStubbed={yOffsetStubbed}
        />

      </mesh>
    </group>
  );
}

export interface RoofTrimLengthProps extends RoofTrimJudithProps {
  side: Side;
  visualizationSettings: DormerVisualizationSettings;
}

export const RoofTrimLengthJudith: FunctionComponent<RoofTrimLengthProps> = ({
                                                                         southFace,
                                                                         roofOverhang,
                                                                         side,
                                                                         visualizationSettings,
                                                                         xOffsetStubbed,
                                                                         yOffsetStubbed,
                                                                       }: RoofTrimLengthProps) => {
  const southSectionFragments = southFace.sectionFragments;
  const stubbedSectionFragment = southSectionFragments.find(x => x.sectionFragmentType === SectionFragmentType.Stubbed);
  const roofTrim = southSectionFragments.find(x => x.sectionFragmentType === SectionFragmentType.RoofTrim);
  const stubbedHeight = fromMmToRenderingMeters(stubbedSectionFragment?.height ?? -1);

  if (!roofTrim) {
    return <></>
  }

  const height = fromMmToRenderingMeters(45)//roofTrim.height);
  const depth = fromMmToRenderingMeters(35)//roofTrim.width);
  const stubbedWidth = fromMmToRenderingMeters(getTotalWidth(southFace)) + roofOverhang.stubbedWestOverhang + roofOverhang.stubbedEastOverhang;

  const width = stubbedWidth;

  const shape = new Shape();

  const overhangAmount = 0.0001;//height;
  const flattopDepth = depth - overhangAmount;

  const flatParthHeight = height;

  shape.moveTo(0, 0);
  shape.lineTo(0, flatParthHeight);
  shape.lineTo(flattopDepth + overhangAmount, flatParthHeight);
  shape.lineTo(flattopDepth + overhangAmount, 0);
  // shape.absarc(flattopDepth,
  //   circleRadius / 2,
  //   circleRadius / 2,
  //   Math.PI / 2,
  //   Math.PI * 1.5,
  //   true);

  shape.lineTo(0, 0);


  shape.closePath();


  const offsetHeight = -(height / 5 * 3);

  let extrudeDepth = overhangAmount;
  let rotation = [0, 0, 0];
  let position = [0, 0, 0];
  const gluee = 0.001;

  if (side === Side.South) {
    extrudeDepth += stubbedWidth;
    rotation = [0, -Math.PI / 2, 0];
    position = [
      -roofOverhang.stubbedWestOverhang + (extrudeDepth) - overhangAmount / 2,
      offsetHeight,
      overhangAmount + roofOverhang.stubbedSouthOverhang - depth
    ];
  } else if (side === Side.East) {
    extrudeDepth += DEFAULT_DORMER_DEPTH;
    rotation = [0, 0, 0];
    position = [
      -roofOverhang.stubbedWestOverhang + stubbedWidth + overhangAmount - depth,
      offsetHeight,
      -(extrudeDepth) + overhangAmount / 2 + gluee + roofOverhang.stubbedSouthOverhang
    ];
  } else if (side === Side.West) {
    extrudeDepth += DEFAULT_DORMER_DEPTH;
    rotation = [0, Math.PI, 0];
    position = [
      -roofOverhang.stubbedWestOverhang + depth - overhangAmount,
      offsetHeight,
      overhangAmount / 2 + gluee + roofOverhang.stubbedSouthOverhang
    ];
  }

  const extrudeSettingsSide = {
    steps: 1,
    depth: extrudeDepth,
    bevelEnabled: false,
  };

  const csgRotation = getCsgSubtractorRotationEuler(visualizationSettings, southFace)
  const csgPosition = getCsgSubtractorPositionEuler(visualizationSettings, southFace);

  const totalWidth = getTotalWidth(southFace);
  const csgBgWidth = totalWidth + 2;

  const totallyNotEstimatedNumber = .673;
  return (
    <group>
      <group
        position={[position[0], position[1], position[2]]}
        rotation={[rotation[0], rotation[1], rotation[2]]}
      >
        <mesh
          key={'rooftrims'}
        >
          <mesh
            key={'rooftrim'}
          >
            <Geometry>
              <Base
                position={[0, 0, 0]}
              >
                <extrudeGeometry
                  args={[shape, extrudeSettingsSide]}
                />
              </Base>
              <Subtraction
                position={[0, 0, -totallyNotEstimatedNumber]}
                rotation={[0, Math.PI / 4, 0]}
                showOperation={false}
              >
                <boxGeometry
                  args={[1, .1, 1]}
                />
              </Subtraction>
              <Subtraction
                position={[0, 0, (extrudeDepth) + (totallyNotEstimatedNumber)]}
                rotation={[0, -Math.PI / 4, 0]}
                showOperation={false}
              >
                <boxGeometry
                  args={[1, .1, 1]}
                />
              </Subtraction>
              {/*I DO NOT KNOW WHY WE NEED TO COPE LIKE THIS */}
              <group
                position={[0, 0, side === Side.West ? (roofOverhang.stubbedSouthOverhang * 2 + overhangAmount) : 0]}>
                <group position={[-xOffsetStubbed, -(stubbedHeight - height / 2) + -yOffsetStubbed, 0]}>
                  <group
                    position={[-position[0], -position[1], -position[2]]}
                  >
                    <group
                      rotation={[-rotation[0], -rotation[1], -rotation[2]]}
                    >
                      <group
                        rotation={[csgRotation.x, csgRotation.y, csgRotation.z]}
                      >
                        <group
                          position={[csgPosition.x, csgPosition.y, csgPosition.z]}
                        >
                          <Conditional
                            condition={side !== Side.South}
                          >
                            <Subtraction
                              showOperation={false}
                            >
                              <CsgSubtractorBox
                                southFace={southFace}
                                visualizationSettings={visualizationSettings}
                              />
                            </Subtraction>
                          </Conditional>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </Geometry>
            <Conditional condition={roofTrim.configuredDormerColor != null}>
              <DormerColorMaterial
                dormerColor={roofTrim?.configuredDormerColor}
              />
            </Conditional>
            <Conditional condition={!roofTrim.configuredDormerColor}>
              <ChromeMaterial />
            </Conditional>
          </mesh>
        </mesh>
      </group>
    </group>
  );
}

