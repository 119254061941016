import {
  ConfigurationTemplateDormerOverhangDto,
  ConfigurationTemplateModelType,
  FaceDto,
  FrameDto,
  FrameVariation,
  SectionFragmentDto, SectionFragmentSide,
  SectionFragmentType, Side
} from "@hec/api-dtos";
import {fromMmToRenderingMeters} from "./NumberHelpers";
export const ROOF_OVERHANG_OFFSET = 200;


export const getStubbedHeight = (face: FaceDto | undefined): number => {
  const sectionFragments = face?.sectionFragments ?? [];
  const stubbed = sectionFragments.find(x => x.sectionFragmentType === SectionFragmentType.Stubbed);
  const stubbedHeight = stubbed?.height ?? 0;

  return returnWarnWrongNumber(stubbedHeight);
}

export const getSplinterWidthFromSectionFragments = (sectionFragments: SectionFragmentDto[]) => {
  // const sectionFragments = face?.sectionFragments ?? [];
  const splinters = sectionFragments.filter(x => x.sectionFragmentType === SectionFragmentType.Splinter);
  const splinter = splinters.find(x => x.splinterSide === SectionFragmentSide.East) ??
    splinters.find(x => x.splinterSide === SectionFragmentSide.West) ??
    splinters.find(x => x.splinterSide === SectionFragmentSide.EastPlusWest) ??
    splinters.find(x => x.splinterSide === SectionFragmentSide.EastPlusSouthPlusWest);

  const splinterHeightData = splinter?.height;


  // TODO: Temporary fix for HEC-510 (Splinter width is never set from backoffice)
  const splinterWidth = (splinter?.width  ?? 0) > 0 ? splinter?.width ?? 0 : splinterHeightData ?? 0;

  return splinterWidth;
}

export const getSplinterWidth = (face: FaceDto | undefined) => {
  const sectionFragments = face?.sectionFragments ?? [];
  return getSplinterWidthFromSectionFragments(sectionFragments);

}

export const getSplinterHeightFromSectionFragments = (sectionFragments: SectionFragmentDto[]) => {
  // const sectionFragments = face?.sectionFragments ?? [];
  const splinters = sectionFragments.filter(x => x.sectionFragmentType === SectionFragmentType.Splinter);
  const splinter = splinters.find(x => x.splinterSide === SectionFragmentSide.Up) ??
    splinters.find(x => x.splinterSide === SectionFragmentSide.Down) ??
    splinters.find(x => x.splinterSide === SectionFragmentSide.EastPlusWest) ??
    splinters.find(x => x.splinterSide === SectionFragmentSide.EastPlusSouthPlusWest);

  return returnWarnWrongNumber(splinter?.height ?? 0);

}

export const getSplinterHeight = (face: FaceDto | undefined) => {
  const sectionFragments = face?.sectionFragments ?? [];

  return getSplinterHeightFromSectionFragments(sectionFragments);
}


export const getCheeksWidth = (face: FaceDto | undefined) => {
  const sectionFragments = face?.sectionFragments ?? [];
  const cheeksSectionFragment = sectionFragments.find(x=> x.sectionFragmentType === SectionFragmentType.Cheeks);
  const cheeksWidth = cheeksSectionFragment?.width ?? 0;
  return returnWarnWrongNumber(cheeksWidth)
}


export const getWidthOfFramesFromFace = (face: FaceDto | undefined): number => {
  const sectionFragments = face?.sectionFragments ?? [];

  // return 0;
  return getWidthOfFrames(sectionFragments);
}

export const getWidthOfFrames = (sectionFragments: SectionFragmentDto[]): number => {

  const frameSectionFragments = sectionFragments.filter(x=> x.sectionFragmentType === SectionFragmentType.Frame);

  const frameSectionsWidth = frameSectionFragments.map(x=> x.width)
  const totalWidthOfFrames = frameSectionsWidth.reduce((accumulator, currentValue) => {
    return accumulator + currentValue
  },0);

  return returnWarnWrongNumber(totalWidthOfFrames);
}

export const getFrameHeight = (face: FaceDto | undefined): number => {
  const sectionFragments = face?.sectionFragments ?? [];
  const frameSectionFragments = sectionFragments.find(x=> x.sectionFragmentType === SectionFragmentType.Frame);

  return frameSectionFragments?.height ?? 0;
}


export const getTotalHeightExcludingStubbed = (face: FaceDto | undefined): number => {
  const sectionFragments = face?.sectionFragments ?? [];

  const frameHeight = getFrameHeight(face);
  const splinter = sectionFragments.find(x => x.sectionFragmentType === SectionFragmentType.Splinter);
  const splinterHeight = splinter?.height ?? 0;

  const result = frameHeight + (splinterHeight * 2)

  return returnWarnWrongNumber(result);
}



export const getTotalHeight = (face: FaceDto | undefined): number => {

  const frameHeight = getFrameHeight(face);

  const splinterHeight = getSplinterHeight(face);

  const stubbedHeight = getStubbedHeight(face);

  const result = frameHeight + (splinterHeight * 2) + stubbedHeight;

  return returnWarnWrongNumber(result);
}


// The sizes of these are not mutable by the customer
export const getWidthOfStaticSections = (face: FaceDto | undefined): number => {
  const sectionFragments = face?.sectionFragments ?? [];

  const splinterWidth = getSplinterWidth(face);

  const cheeksSectionFragment = sectionFragments.find(x=> x.sectionFragmentType === SectionFragmentType.Cheeks);
  const cheeksWidth = cheeksSectionFragment?.width ?? 0;

  const result = (splinterWidth * 2) + (cheeksWidth * 2);

  return returnWarnWrongNumber(result);
}


export const getTotalWidth = (face: FaceDto | undefined): number => {
  const sectionFragments = face?.sectionFragments ?? [];

  const splinterWidth = getSplinterWidth(face);

  const cheeksSectionFragment = sectionFragments.find(x=> x.sectionFragmentType === SectionFragmentType.Cheeks);
  const cheeksWidth = cheeksSectionFragment?.width ?? 0;

  const result =  getWidthOfFramesFromFace(face) + (splinterWidth * 2) + (cheeksWidth * 2);

  return returnWarnWrongNumber(result);
}

export const getThickestSplinterSide = (face: FaceDto | undefined): number => {
  const sectionFragments = face?.sectionFragments ?? [];

  const splinter = sectionFragments.find(x => x.sectionFragmentType === SectionFragmentType.Splinter);
  const splinterWidth = splinter?.width ?? 0;
  const splinterHeight = splinter?.height ?? 0;

  const result = splinterWidth > splinterHeight ? splinterWidth : splinterHeight;

  return returnWarnWrongNumber(result);
}




export const getTotalSplinterWidth = (face: FaceDto | undefined): number => {
  const splinterWidth = getSplinterWidth(face);


  const result = getWidthOfFramesFromFace(face) + splinterWidth * 2;

  return returnWarnWrongNumber(result);
}

export const getFrames = (face : FaceDto | undefined): FrameDto[] => {
  const sectionFragments = face?.sectionFragments ?? [];
  const frames: FrameDto[] = sectionFragments.filter(x=> x.sectionFragmentType === SectionFragmentType.Frame) as FrameDto[];

  return frames;
}



const MOCK_PANEL_ID = "MOCK_PANEL_ID";

export const getMaxFrameIndex = (face : FaceDto | undefined): number | null => {
  const frames = getFrames(face);

  const nonMockPanels = frames.filter(x => x.id !== MOCK_PANEL_ID && x.sort != null && x.sort > -1);

  if (nonMockPanels.length === 0) {
    return null;
  }

  const sorts: number[] = nonMockPanels.map(x=> x.sort!);

  const max = Math.max(...sorts);
  return max;
}


export const getMockFillerPanel = (face: FaceDto | null, width: number | null, height: number | null): SectionFragmentDto | null  => {

  if (!face || !width || !height) {
    return null;
  }

  const reservedWidth = getCheeksWidth(face) * 2 + getSplinterWidth(face) * 2;

  const widthOfFrames = getWidthOfFramesFromFace(face);

  const desiredWidth = width - (reservedWidth + widthOfFrames);

  if (desiredWidth < 1) {
    return null;
  }


  const cheek: SectionFragmentDto | undefined = (face.sectionFragments ?? []).find(x => x.sectionFragmentType === SectionFragmentType.Cheeks);

  const reservedHeight = getSplinterHeight(face) * 2 + getStubbedHeight(face);

  const desiredHeight = height - reservedHeight;


  const maxFrameIndex = getMaxFrameIndex(face);

  const result: FrameDto = {
    name: "Filler",
    dormerMaterialId: "",
    // TODO: Add
    // dormerMaterial: ,
    minWidth: null,
    maxWidth: null,
    minHeight: null,
    maxHeight: null,
    variation: FrameVariation.Panel,
    supportedSizes: [],
    priceCalculationStrategyId: null,
    isSelectableFrame: false,
    id: MOCK_PANEL_ID,
    faceId: null,
    copiedFromId: null,
    // copiedFrom: new SectionFragmentDto,
    sort: maxFrameIndex !== null ? (maxFrameIndex + 1) : 0,
    width: desiredWidth,
    height: desiredHeight,
    sectionFragmentType: SectionFragmentType.Frame,
    isDefaultForTemplate: false,
    organisationId: null,
    orderId: null,
    configurationTemplateId: null,
    faceConfigurationId: "",
    isOrganisationAddable: false,
    isUserSelectable: false,
    belongsToUser: false,
    configuredDormerColor: cheek?.configuredDormerColor,
    configuredDormerColorId: cheek?.configuredDormerColor?.id
  } as unknown as FrameDto;

  return result;
}

export const degreesToRadians = (degrees:number) => {
  return degrees * (Math.PI / 180);
}




export const returnWarnWrongNumber = (num: number) => {

  if (num < 1) {
    console.warn(`renderig num was ${num}`);
    return 1;
  }

  return num;
}

export class RoofOverhangViewModel {
  stubbedSouthOverhang: number;
  stubbedEastOverhang: number;
  stubbedWestOverhang: number;

  constructor(stubbedSouthOverhang: number, stubbedEastOverhang: number, stubbedWestOverhang: number) {
    this.stubbedSouthOverhang = stubbedSouthOverhang;
    this.stubbedEastOverhang = stubbedEastOverhang;
    this.stubbedWestOverhang = stubbedWestOverhang;
  }
}


export const getRoofOverhang = (overhangDto: ConfigurationTemplateDormerOverhangDto):RoofOverhangViewModel =>
  new RoofOverhangViewModel(fromMmToRenderingMeters(overhangDto.stubbedSouthOverhang), fromMmToRenderingMeters(overhangDto.stubbedEastOverhang), fromMmToRenderingMeters(overhangDto.stubbedWestOverhang));

