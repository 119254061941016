import React, {FunctionComponent} from "react";
import {FrameDto} from "@hec/api-dtos";
import {fromMmToRenderingMeters} from "@hec/core";
import {Box} from "@react-three/drei";
import {AttachSide, CladdingGlobalMaterial} from "../CladdingGlobalMaterial";
import {DormerColorMaterial} from "../DormerColorMaterial";
import {OPENING_FRAME_ROD, FRAME_SURROUNDING_ROD_THICKNESS} from "../../../../constants";
import {Base3dProps} from "../Base3dProps";

interface SingularFillerPanelFrameProps extends Base3dProps {
  frame: FrameDto;
  amountOfFrames: number;
}

const _fillerDepth = fromMmToRenderingMeters(50);

export const SingularFillerPanelFrame: FunctionComponent<SingularFillerPanelFrameProps> = ({ frame, amountOfFrames, visualizationSettings }: SingularFillerPanelFrameProps) => {
  // const frameSurroundingRodThickness = fromMmToRenderingMeters(visualizationSettings.sizeFrameSurroundingRod);

  const frameRabbetSideThickness = fromMmToRenderingMeters(OPENING_FRAME_ROD)


  const heightAmountToSubtractForSurroundingRods = 0; //frameSurroundingRodThickness * 2;
  const widthAmountToSubtractForSurroundingRods = 0;//((frameSurroundingRodThickness / amountOfFrames));// * 2);

  const frameHeight = fromMmToRenderingMeters(frame?.height ?? 0) - heightAmountToSubtractForSurroundingRods;
  const frameWidth = fromMmToRenderingMeters(frame?.width ?? 0) - widthAmountToSubtractForSurroundingRods;


  return <group position={[0,0,frameRabbetSideThickness]}>
    <group key={`filler-panel-frame-w_${frameWidth}-h_${frameHeight}`} position={[frameWidth / 2, frameHeight /2 , -(_fillerDepth / 2)]}>
      <Box
        args={[frameWidth,frameHeight,_fillerDepth]}
        receiveShadow
        castShadow
      >
        <DormerColorMaterial
          dormerColor={frame?.configuredDormerColor}
          attachSide={AttachSide.west}
          width={_fillerDepth}
          height={frameHeight}
        />
        <DormerColorMaterial
          dormerColor={frame?.configuredDormerColor}
          attachSide={AttachSide.east}
          width={_fillerDepth}
          height={frameHeight}
        />
        <DormerColorMaterial
          dormerColor={frame?.configuredDormerColor}
          attachSide={AttachSide.up}
        />
        <DormerColorMaterial
          dormerColor={frame?.configuredDormerColor}
          attachSide={AttachSide.down}
        />
        <DormerColorMaterial
          dormerColor={frame?.configuredDormerColor}
          attachSide={AttachSide.north}
          width={frameWidth}
          height={frameHeight}
        />
        <DormerColorMaterial
          dormerColor={frame?.configuredDormerColor}
          attachSide={AttachSide.south}
          width={frameWidth}
          height={frameHeight}
        />
      </Box>
    </group>
  </group>

}


