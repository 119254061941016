import {FrameDto, OrderDto, SectionFragmentDto, SectionFragmentType} from "@hec/api-dtos";
import {DormerRootState} from "../DormerRootState";
import {getSectionFragmentsFromOrderByType} from "./Util";

export const getOrderSelector = (state: DormerRootState): OrderDto | null => {
  return state.order.order;
}

export const getOrderIsDirtySelector = (state: DormerRootState): boolean => {
  return state.order.isDirty ?? true;
}


export const getConfiguredSectionFragmentsFromOrder = (order: OrderDto, sfType: SectionFragmentType | null = null): SectionFragmentDto[] => {
  const sfs: SectionFragmentDto[] = getSectionFragmentsFromOrderByType(order, SectionFragmentType.Frame) as SectionFragmentDto[];

  return sfs.filter(sf => sf.faceId !== null && sf.orderId != null);
}
