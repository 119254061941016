import React, {useEffect, useMemo, useRef} from "react";
import {BufferGeometry, ExtrudeGeometry, Float32BufferAttribute, Matrix4, Mesh, Shape} from "three";
import {degToRad} from "three/src/math/MathUtils";
import {DormerColorMaterial} from "./DormerColorMaterial";
import {AttachSide} from "./CladdingGlobalMaterial";
import {DormerColorViewModel} from "@hec/api-dtos";

interface CustomPrismProps {
  topWidth?: number;
  yHeight?: number;
  slantedSideWidth?: number;
  dormerColor?: DormerColorViewModel | null | undefined
}

interface TriangleSides {
  adjacent: number;
  opposite: number;
}

const hypotenuse = 30;
const angleAlpha = 30;
const angleBeta = 60;

//Right Triangle Side and Angle Calculator
export const RightTriangleSideandAngleCalculatorHelper = (hypotenuse: number, angleAlpha: number, angleBeta: number): TriangleSides => {
  // Ensure the angles sum up to 90 for a right triangle
  if (angleAlpha + angleBeta !== 90) {
    throw "The angles of a right triangle must sum up to 90 degrees.";
  }

  // Convert angles from degrees to radians for JavaScript trig functions
  const alphaRadians = angleAlpha * Math.PI / 180;
  const betaRadians = angleBeta * Math.PI / 180;

  // Calculate the lengths of the sides using trigonometry
  const adjacent = hypotenuse * Math.cos(alphaRadians);
  const opposite = hypotenuse * Math.sin(alphaRadians);

  // The returned object contains the lengths of the adjacent and opposite sides
  return { adjacent, opposite };
}

export const CustomPrismCalculationHelper = (hypotenuse: number): TriangleSides => RightTriangleSideandAngleCalculatorHelper(hypotenuse, angleAlpha, angleBeta);

/// They Y side here is the long straight side.
export const CalculateSideYForCustomPrism = (x: number) => {
  const result = RightTriangleSideandAngleCalculatorHelper(x, angleAlpha, angleBeta);

  return result.adjacent;
};


export const CustomPrism = ({ topWidth = 5, yHeight = 1.744, slantedSideWidth = 0.2, dormerColor }: CustomPrismProps) => {
  const meshRef = useRef<Mesh>(null);

  const geometry = useMemo(() => {
    // Base width calculated from the topWidth and yHeight due to the angle constraints
    const baseWidth = topWidth + (yHeight / Math.sqrt(3));

    // Create the shape for the cross-section of the prism
    const shape = new Shape();
    shape.moveTo(0, 0); // Bottom left
    shape.lineTo(baseWidth, 0); // Bottom right
    shape.lineTo(baseWidth, yHeight); // Top right
    shape.lineTo(baseWidth - topWidth, yHeight); // Top left for slanted panel
    shape.lineTo(0, 0); // Close the shape

    // Create the extrude settings
    const extrudeSettings = {
      depth: slantedSideWidth,
      bevelEnabled: false,
    };

    // Create the geometry by extruding the shape
    const geom = new ExtrudeGeometry(shape, extrudeSettings);
    geom.computeVertexNormals();

    return geom;
  }, [topWidth, yHeight, slantedSideWidth]); // Dependency array ensures geometry updates when props change

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.applyMatrix4(new Matrix4().makeRotationY(degToRad(90)));
    }
  }, [meshRef]);

  const makeBasicMaterial = false;


  return (
    <mesh
      ref={meshRef}
      geometry={geometry}
      receiveShadow={true}
      castShadow={true}
      position={[0, 0, 0]}
    >
      <DormerColorMaterial
        makeBasicMaterial={makeBasicMaterial}
        dormerColor={dormerColor}
        attachSide={AttachSide.west}
        width={slantedSideWidth}
        height={yHeight}
      />
      <DormerColorMaterial
        makeBasicMaterial={makeBasicMaterial}
        dormerColor={dormerColor}
        attachSide={AttachSide.east}
        width={slantedSideWidth}
        height={yHeight}
      />
      <DormerColorMaterial
        makeBasicMaterial={makeBasicMaterial}
        dormerColor={dormerColor}
        attachSide={AttachSide.up}
      />
      <DormerColorMaterial
        makeBasicMaterial={makeBasicMaterial}
        dormerColor={dormerColor}
        attachSide={AttachSide.down}
      />
      <DormerColorMaterial
        makeBasicMaterial={makeBasicMaterial}
        dormerColor={dormerColor}
        attachSide={AttachSide.north}
        width={slantedSideWidth}
        height={yHeight}
      />
      <DormerColorMaterial
        makeBasicMaterial={makeBasicMaterial}
        dormerColor={dormerColor}
        attachSide={AttachSide.south}
        width={slantedSideWidth}
        height={yHeight}
      />
    </mesh>
  );
};
