import * as THREE from 'three'
import {Canvas} from '@react-three/fiber';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  CheeksDto,
  ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType,
  ConfigurationTemplateDormerOverhangDto,
  ConfigurationTemplateDto,
  FaceDto,
  OrderDto,
  SectionFragmentDto,
  SectionFragmentSide,
  SectionFragmentType,
  Side
} from "@hec/api-dtos";
import {PlaceHolder} from "./Placeholder";
import {Cheeks, CheekSideRenderingObjectSide, CustomPrismCalculationHelper, SlantedCheeks, SlantedStubbed, Stubbed} from "./Parts";
import {Conditional, GroupHelper} from "@hec/components/v2";
import {CameraRevised} from "./CameraRevised";
import {
  degreesToRadians,
  fromMmToRenderingMeters,
  getMockFillerPanel,
  getRoofOverhang,
  getThickestSplinterSide,
  getTotalHeightExcludingStubbed,
  getTotalSplinterWidth,
  getTotalWidth,
  getWidthOfFramesFromFace
} from "@hec/core";
import {FaceSplinters} from "./Parts/AllSplinters";
import {CSGWrapper} from "./CSGSubtract";
import {SectionFragmentsRenderer} from "./Parts/SectionFragments/SectionFragmentsRenderer";
import {RoofTrim} from "./Parts/RoofTrim/RoofTrim";
import _ from "lodash";
import {DormerVisualizationSettings} from "@hec/models";
import {HashObject} from "@hec/utils";
import {Base, Geometry, Subtraction} from "@react-three/csg";
import {CsgSubtractor} from "./Parts/CsgSubtractor";
import {FrameOverlayRenderer} from "./Parts/SectionFragments/FrameOverlayRenderer";
import { getGPUTier } from 'detect-gpu';

export interface DormerRendererProps {
  configurationTemplate: ConfigurationTemplateDto,
  order: OrderDto | null,
  visualizationSettings: DormerVisualizationSettings,
}

export const getFaceConfiguration = (order: OrderDto | null) => {
  return order?.faceConfiguration;
}

const FAKE_PRODUCTION = false;
export const SHOW_DEVELOPMENT_THINGS = (window?.location?.host ?? '').indexOf('localhost') === 0 && !FAKE_PRODUCTION;

export const CANVAS_CONTAINER_ID = 'canvas-container';
export const CANVAS_THREE_ID = 'dormer-canvas-three';
export const DEFAULT_DORMER_DEPTH = 20;

export const Dormer3dRenderer: FunctionComponent<DormerRendererProps> = ({
                                                                           configurationTemplate,
                                                                           order,
                                                                           visualizationSettings
                                                                         }: DormerRendererProps) => {
  const [tier, setTier] = useState(0);

  useEffect(() => {
    const detectTier = async () => {
      const gpuTier = await getGPUTier();
      setTier(gpuTier.tier);
    };
    detectTier();
  }, []);

  const overhangDto: ConfigurationTemplateDormerOverhangDto = configurationTemplate?.configurationTemplateDormerOverhang;

  const selectedConfigurationTemplateSouthFace = configurationTemplate?.templateFaceConfiguration?.faces?.find(x => x?.side === Side.South);
  if (selectedConfigurationTemplateSouthFace === undefined) {
    return <></>
  }
  const southFaceUncloned =
    order?.faceConfiguration?.faces?.find(x => x.side === Side.South) ?? selectedConfigurationTemplateSouthFace;

  //If another is selected than currently is in order
  const orderIsDifferentThanSelection = order?.faceConfiguration?.configurationTemplateId !== configurationTemplate.id && !!configurationTemplate && !!order;
  let southFace: FaceDto | undefined = undefined;

  // Need new memalloc's so we can add potential mock panels wo mutating state, so we need to clone this.
  if (orderIsDifferentThanSelection) {
    southFace = _.clone(selectedConfigurationTemplateSouthFace);
  } else {
    southFace = _.clone(southFaceUncloned);
  }

  const getOrder = () => {
    return orderIsDifferentThanSelection ? null : order;
  }

  const roofOverhang = getRoofOverhang(overhangDto);


  const faceConfigurationWidth = getOrder()?.faceConfiguration?.width ?? configurationTemplate.defaultWidth;
  const faceConfigurationHeight = getOrder()?.faceConfiguration.height ?? configurationTemplate.defaultHeight;

  // TODO: move to some kind of reducer/saga and omit the clone.
  const mockFillerPanel = getMockFillerPanel(southFace ?? null, faceConfigurationWidth ?? null, faceConfigurationHeight ?? null)

  if (southFace?.sectionFragments && mockFillerPanel) {
    southFace!.sectionFragments = [...southFace.sectionFragments, mockFillerPanel];
  }
  const southSectionFragments = southFace?.sectionFragments ?? [];


  const frames = southSectionFragments.filter(x => x.sectionFragmentType === SectionFragmentType.Frame);
  const frame = frames.find(x=> x);

  const framesReRenderKey = HashObject(frames);
  const totalWidthOfFrames = getWidthOfFramesFromFace(southFace);

  const frameHeight = frame?.height ?? 0;
  const splinter = southSectionFragments.find(x => x.sectionFragmentType === SectionFragmentType.Splinter);
  const splinterHeight = splinter?.height ?? 0;
  const splinterWidth = splinter?.width ?? 0;

  const stubbeds = southSectionFragments.filter(x => x.sectionFragmentType === SectionFragmentType.Stubbed || x.sectionFragmentType === SectionFragmentType.RoofTrim);

  const stubbedsReRenderKey = HashObject(stubbeds);
  console.log(`stubbeds reRenderKey: ${stubbedsReRenderKey}`);


  const cheeks = southSectionFragments.filter(x => x.sectionFragmentType === SectionFragmentType.Cheeks) as CheeksDto[];

  const cheeksReRenderKey = HashObject(cheeks);
  console.log(`cheeks reRenderKey: ${cheeksReRenderKey}`);
  const cheek: SectionFragmentDto | undefined = cheeks.find(x => x.cheekSide === SectionFragmentSide.South);

  const totalWidth = getTotalWidth(southFace);
  const totalHeight = getTotalHeightExcludingStubbed(southFace);

  let yOffsetStubbed = fromMmToRenderingMeters((splinterHeight * 2) + frameHeight);
  const xOffsetStubbed = -fromMmToRenderingMeters((totalWidth) / 2);
  const yOffsetTopSplinter = fromMmToRenderingMeters(splinterHeight + frameHeight);
  const yOffsetFrame = fromMmToRenderingMeters(splinterHeight);

  const xOffsetWestCheek = -fromMmToRenderingMeters((totalWidth) / 2);
  const xOffsetEastCheek = fromMmToRenderingMeters(totalWidth / 2 - (cheek?.width ?? 0));

  const xOffSetSplinter = -fromMmToRenderingMeters(getTotalSplinterWidth(southFace) / 2);

  // const xOffSetFrames = (-fromMmToRenderingMeters((getWidthOfFrames(southFace)) /2)) + fromMmToRenderingMeters(getThickestSplinterSide(southFace));

  const xOffSetFrames = -fromMmToRenderingMeters(getWidthOfFramesFromFace(southFace) / 2);
  const yOffSetFrames = fromMmToRenderingMeters(getThickestSplinterSide(southFace));


  const csgBgWidth = totalWidth + 2;
  const csgBgHeight = 50;
  const csgBgDepth = csgBgHeight;

  const slopeDegrees = visualizationSettings?.visualizationType === ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType.Slanted ? 45 : 25;
  const rotationAngleFromVertical = 90 - slopeDegrees;
  const rotationAngle = -degreesToRadians(rotationAngleFromVertical);


  const reRenderKey = `${xOffsetWestCheek}-${xOffsetEastCheek}-h_${totalHeight}-w_${totalWidth}-fcw_${faceConfigurationWidth}-fch_${faceConfigurationHeight}-`;


  const csgSubtractor = <CsgSubtractor visualizationSettings={visualizationSettings} southFace={southFace}/>;


  if(visualizationSettings.visualizationType === ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType.Slanted) {
    // When slanted the height is defined as the hypotenuse side of the slanted configuration so we need to calcualte the adjacent.
    // (Trichometry definitions btw)
    yOffsetStubbed = CustomPrismCalculationHelper(yOffsetStubbed).adjacent;
  }

  const slantedVisualizationRotationAngleFromVertical = 15;
  const slantedVisualizationRotationAngle = -degreesToRadians(slantedVisualizationRotationAngleFromVertical);


  const box = new THREE.BoxGeometry()

  return <div
    id={CANVAS_CONTAINER_ID}
    className="canvas-container"
    style={{width: '100%', height: '100%'}}
  >
    <Canvas
      shadows={tier > 1}
      gl={{
        localClippingEnabled: true,
        preserveDrawingBuffer: true,
        antialias: true,
      }}
      style={{background: "#FBFBFB"}}
      id={CANVAS_THREE_ID}

    >
{/*      <mesh>
        <Geometry>
          <Base
            // geometry={box}
          >
            <boxGeometry args={[2, 2, 2]}/>

          </Base>
          <Subtraction position={[0.5, 0.5, 0.5]}>
            <boxGeometry args={[0.3, 10, 10]}/>
          </Subtraction>
        </Geometry>
        <meshStandardMaterial color="red"/>
      </mesh>*/}

      <CameraRevised isDevelopmentBuild={SHOW_DEVELOPMENT_THINGS} offSet={{x: 0, y: fromMmToRenderingMeters(totalHeight /2), z: 0}} tier={tier}/>
      <group
        // rotation={[0, -Math.PI / 36, 0]}
      >
        <Conditional condition={SHOW_DEVELOPMENT_THINGS}>
          <axesHelper args={[100000]}/>
        </Conditional>
        <Conditional condition={southFace != null} fallback={<PlaceHolder/>}>

          {/*{csgSubtractor}*/}

          <Conditional
            condition={visualizationSettings.visualizationType === ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType.Slanted}>
            <group rotation={[slantedVisualizationRotationAngle, 0, 0]} key={`sfg-${reRenderKey}`}>
              <group key={`sfg-${reRenderKey}`}>
                <GroupHelper position={[xOffSetSplinter, 0, 0]} showHelper={false}>
                  <FaceSplinters face={southFace!} overrideColor={cheek?.configuredDormerColor ?? undefined}/>
                </GroupHelper>
                <GroupHelper position={[xOffSetFrames, yOffSetFrames, 0]} showHelper={false}>
                  <SectionFragmentsRenderer face={southFace!} visualizationSettings={visualizationSettings}/>
                </GroupHelper>
                <FrameOverlayRenderer face={southFace!} visualizationSettings={visualizationSettings}/>
              </group>
            </group>

            <CSGWrapper
              key={`csg-stubbed-${stubbedsReRenderKey}-w_${reRenderKey}-${roofOverhang.stubbedEastOverhang}-${roofOverhang.stubbedWestOverhang}-${roofOverhang.stubbedSouthOverhang}-material-${cheek?.configuredDormerColorId}`}
              mesh1={
                <group rotation={[-slantedVisualizationRotationAngle, 0, 0]}>
                  <GroupHelper position={[xOffsetStubbed, yOffsetStubbed, 0]} showHelper={false}>
                    <SlantedStubbed key={`stubbed-${totalWidth}`} southFace={southFace!}
                                    overrideColor={cheek?.configuredDormerColor ?? undefined}/>
                  </GroupHelper>

                </group>
              }
              mesh2={csgSubtractor}
            />

            <CSGWrapper
              key={`csg-${cheeksReRenderKey}-cheeks-${reRenderKey}-material-${cheek?.configuredDormerColorId}`}
              mesh1={
                <group
                  rotation={[-slantedVisualizationRotationAngle, 0, 0]}
                >
                  <GroupHelper position={[xOffsetWestCheek, 0, 0]} showHelper={false}>
                    <SlantedCheeks southFace={southFace!}/>
                  </GroupHelper>
                  <GroupHelper position={[xOffsetEastCheek, 0, 0]} showHelper={false}>
                    <SlantedCheeks southFace={southFace!}/>
                  </GroupHelper>
                </group>
              }
              mesh2={<group
                key={`${cheeksReRenderKey}-mesh-2csg-cheeks-${reRenderKey}-material-${cheek?.configuredDormerColorId}`}>
                {csgSubtractor}
              </group>}
            />
          </Conditional>

          <Conditional
            condition={visualizationSettings.visualizationType === ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType.Upright}>
            <group key={`sfg-${framesReRenderKey}-${reRenderKey}`}>
              <GroupHelper position={[xOffSetSplinter, 0, 0]} showHelper={false}>
                <FaceSplinters face={southFace!} overrideColor={cheek?.configuredDormerColor ?? undefined}/>
              </GroupHelper>
              <GroupHelper position={[xOffSetFrames, yOffSetFrames, 0]} showHelper={false}>
                <SectionFragmentsRenderer face={southFace!} visualizationSettings={visualizationSettings}/>
              </GroupHelper>
              <FrameOverlayRenderer face={southFace!} visualizationSettings={visualizationSettings}/>
            </group>

            <CSGWrapper
              key={`csg-stubbed-w_-${stubbedsReRenderKey}-${reRenderKey}-${roofOverhang.stubbedEastOverhang}-${roofOverhang.stubbedWestOverhang}-${roofOverhang.stubbedSouthOverhang}-material-${cheek?.configuredDormerColorId}`}
              mesh1={
                <GroupHelper position={[xOffsetStubbed, yOffsetStubbed, 0]} showHelper={false}>
                  <Stubbed key={`stubbed-${totalWidth}`} southFace={southFace!} roofOverhang={roofOverhang}
                           overrideColor={cheek?.configuredDormerColor ?? undefined}/>
                  {/*<RoofTrim*/}
                  {/*  southFace={southFace!}*/}
                  {/*  roofOverhang={roofOverhang}*/}
                  {/*/>*/}
                </GroupHelper>
              }
              mesh2={csgSubtractor}
            />
            <GroupHelper position={[xOffsetStubbed, yOffsetStubbed, 0]} showHelper={false}>
              <RoofTrim
                southFace={southFace!}
                roofOverhang={roofOverhang}
                visualizationSettings={visualizationSettings}
                xOffsetStubbed={xOffsetStubbed}
                yOffsetStubbed={yOffsetStubbed}
              />
            </GroupHelper>
            <CSGWrapper
              key={`${cheeksReRenderKey}-csg-cheeks-${reRenderKey}-material-${cheek?.configuredDormerColorId}`}
              mesh1={
                <group>
                  <GroupHelper position={[xOffsetWestCheek, 0, 0]} showHelper={false}>
                    <Cheeks key={`${cheeksReRenderKey}-cheek-1`}
                            cheekRenderingObjectSide={CheekSideRenderingObjectSide.west} southFace={southFace!}
                            cheeksOverhang={overhangDto.cheeksOverhang ?? 0}/>
                  </GroupHelper>
                  <GroupHelper position={[xOffsetEastCheek, 0, 0]} showHelper={false}>
                    <Cheeks key={`${cheeksReRenderKey}-cheek-2`}
                            cheekRenderingObjectSide={CheekSideRenderingObjectSide.east} southFace={southFace!}
                            cheeksOverhang={overhangDto.cheeksOverhang ?? 0}/>
                  </GroupHelper>
                </group>
              }
              mesh2={<group key={`mesh-2csg-cheeks-${reRenderKey}-material-${cheek?.configuredDormerColorId}`}>
                {csgSubtractor}
              </group>}
            />
          </Conditional>


          {/* FOR DEBUGGING SLOPING ANGLE*/}
          {/*<mesh*/}
          {/*  rotation={[rotationAngle, 0, 0]}*/}
          {/*>*/}
          {/*  <mesh*/}
          {/*    position={[0,height /2,-depth /2]}*/}
          {/*  >*/}
          {/*    <boxGeometry args={[width, height, depth]} />*/}
          {/*    <meshStandardMaterial attach="material-0" color="red" />*/}
          {/*    <meshStandardMaterial attach="material-1" color="blue" />*/}
          {/*    <meshStandardMaterial attach="material-2" color="yellow" />*/}
          {/*    <meshStandardMaterial attach="material-3" color="green" />*/}
          {/*    <meshStandardMaterial attach="material-4" color="purple" />*/}
          {/*    <meshStandardMaterial attach="material-5" color="pink" />*/}
          {/*  </mesh>*/}
          {/*</mesh>*/}

        </Conditional>
      </group>
    </Canvas>
  </div>
}
