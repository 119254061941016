import React, {FunctionComponent, useEffect, useState} from "react";
import {
  CheeksDto, ConfigurationTemplateDto,
  DormerMaterialViewModel, FrameDto,
  FrameVariation,
  SectionFragmentDto,
  SectionFragmentSide,
  SectionFragmentType,
  StubbedDto
} from "@hec/api-dtos";
import {OptionList} from "../OptionList";
import {getClientInformationSelector, getSelectedConfigurationTemplateSelector} from "@hec/dal";
import {useSelector} from "react-redux";
import {Dropdown} from "../Dropdown";
import {useTranslation} from "react-i18next";
import {Button} from "../Button";
import {getContrastColor} from "@hec/utils";
import {Conditional} from "../Conditional";

export interface SelectionForSectionFragment {
  [key: string]: string | null
}

export interface MaterialAndColorSelectionDropDownProps {
  sectionFragments: SectionFragmentDto[];
  typePrefix?: string;
  selectedColorChanged: (colorSelection: SelectionForSectionFragment) => void;
  deGroupableTypes: SectionFragmentType[]
}

interface IdenticalCheckViewModel {
  sectionFragmentId: string;
  materialIds: string[];
}

interface GroupedSectionFragmentIdsModel {
  groupedSectionFragmentIds: string[]
  groupedSectionFragments: SectionFragmentDto[]
  identicalSelectedColorId: string | null;
}

interface IdenticalCheckResultViewModel {
  // groupedSectionFragmentIds: string[][]
  groupedSectionFragmentIds: GroupedSectionFragmentIdsModel[];
}


function groupByMaterialIds(sectionFragments: SectionFragmentDto[], deGroupedTypes: SectionFragmentType[]): IdenticalCheckResultViewModel {
  // This map will store the grouped section fragments by their sorted material IDs
  const groups = new Map<string, { fragmentIds: string[], fragments: SectionFragmentDto[], colors: Set<string> }>();

  sectionFragments.forEach(fragment => {
    // Extract material IDs, sort them, and use as a key for grouping
    const materialIds = getAvailableDormerMaterials(fragment)
      .map(material => material.id!)
      .sort();
    let key = materialIds.join(',');

    console.log('should hit!')
    // Hacky fix for also forcing degroup off the panel frame variation type in case of degroup off cheeks.
    if (deGroupedTypes.indexOf(SectionFragmentType.Cheeks) !== -1 && fragment.sectionFragmentType === SectionFragmentType.Frame && (fragment as FrameDto)?.variation === FrameVariation.Panel) {
      key = `${fragment.id}-${fragment.tempId}-${key}`
    }


    // If we force seperate groups for this type lets add the unique id to the type.
    if (deGroupedTypes.indexOf(fragment.sectionFragmentType) !== -1) {
      // Don't allow degrouping of FrameVariation window, because it is tracked by FrameSurroundingRod
      if((fragment as FrameDto)?.variation !== FrameVariation.Window) {
        key = `${fragment.id}-${fragment.tempId}-${key}`
        // Lets force same if any variation other than window is present other than windows i
        if((fragment as FrameDto)?.variation != null) {
          key = `${fragment.sectionFragmentType}-${fragment.variation === FrameVariation.Panel ? 'panel' : ''}`
        }
      }

    }

    // Initialize the group if this is the first time we've seen this key
    if (!groups.has(key)) {
      groups.set(key, {fragmentIds: [], fragments: [], colors: new Set()});
    }

    const group = groups.get(key)!;
    group.fragmentIds.push(fragment.id ?? fragment.tempId!);  // Add the fragment ID to the group
    group.fragments.push(fragment);

    // Add the configuredDormerColorId to the set if it exists
    if (fragment.copiedFrom?.configuredDormerColorId) {
      group.colors.add(fragment.copiedFrom.configuredDormerColorId);
    }
  });

  const result: GroupedSectionFragmentIdsModel[] = [];

  // Create the final grouped structure
  groups.forEach(({fragmentIds, fragments, colors}) => {
    const identicalSelectedColorId = colors.size === 1 ? Array.from(colors)[0] : null;
    result.push({
      groupedSectionFragmentIds: fragmentIds,
      groupedSectionFragments: fragments,
      identicalSelectedColorId: identicalSelectedColorId
    });
  });

  return {groupedSectionFragmentIds: result};
}

interface MaterialOption {
  displayName: string,
  materialId: string,
  colors: MaterialOptionColor[]
}

interface MaterialOptionColor {
  colorId: string,
  displayName: string,
  imageUrl: string,
  colorCode: string,
  isSelected: boolean,
}

interface DropdownDataViewModel {
  sectionFragmentIds: string[],
  sectionFragments: SectionFragmentDto[],
  materialOptions: MaterialOption[]
}

export const MaterialAndColorSelectionDropDown: FunctionComponent<MaterialAndColorSelectionDropDownProps> = ({
                                                                                                               sectionFragments,
                                                                                                               typePrefix,
                                                                                                               selectedColorChanged,
                                                                                                               deGroupableTypes,
                                                                                                             }) => {

  const [deGroupedTypes, setGroupedTypes] = useState<SectionFragmentType[]>([]);

  const identicalMaterialIdsCheckResult = groupByMaterialIds(sectionFragments, deGroupedTypes);

  const getSelectedColorStateForSectionFragmentIds = (sectionFragmentIds: string[], selectedColorId: string, currentSelectedColorsState: SelectionForSectionFragment | null = null) => {
    // Updating selectedColors for all sectionFragmentIds in this group
    const updatedColors: SelectionForSectionFragment = {...(currentSelectedColorsState ?? {})};

    sectionFragmentIds.forEach(sfId => {
      updatedColors[sfId] = selectedColorId;
    });
    return updatedColors;
  }

  // Prepare data for rendering: map each group to a dropdown with options.
  let dropdownData: DropdownDataViewModel[] = identicalMaterialIdsCheckResult.groupedSectionFragmentIds.map(groupVm => {
    const group = groupVm.groupedSectionFragmentIds;
    // Find the first sectionFragment in each group to represent the dropdown.
    const representativeFragment = sectionFragments.find(fragment => group.includes(fragment.id ?? fragment.tempId!));

    // Assuming 'representativeFragment' exists and has at least one material option available.
    const materialOptions: MaterialOption[] = representativeFragment ? getAvailableDormerMaterials(representativeFragment).map(material => ({
      materialId: material.id!,
      displayName: material.name,
      colors: material.dormerColors.map(color => ({
        colorId: color.id!,
        displayName: color.name,
        imageUrl: color.textureUrl,
        colorCode: color.colorCode,
        isSelected: groupVm.identicalSelectedColorId === color.id!
      })),
    })) : [];

    return {
      sectionFragmentIds: group,
      sectionFragments: sectionFragments.filter(fragment => group.includes(fragment.id ?? fragment.tempId!)),
      materialOptions,
    };
  });


  // Filter out dropdowns with only one color option
  dropdownData = dropdownData.filter(data => data.materialOptions.flatMap(x=> x.colors).length > 1);


  const returnEmpty = dropdownData.length === 0;


  let potentialInitialState: SelectionForSectionFragment = {};

  dropdownData.forEach(dd => {
    const selectedColorForDdItem = dd.materialOptions?.find(x => x.colors?.find(x => x.isSelected))?.colors?.find(x => x.isSelected);
    if (!selectedColorForDdItem) {
      return;
    }

    const potentialSelectedColorState = getSelectedColorStateForSectionFragmentIds(dd.sectionFragmentIds, selectedColorForDdItem.colorId);

    potentialInitialState = {...potentialInitialState, ...potentialSelectedColorState};
  });

  const [selectedColors, setSelectedColors] = useState<SelectionForSectionFragment>(potentialInitialState);
  const clientInformation = useSelector(getClientInformationSelector);
  const {t} = useTranslation();


  const setSelectedColorStateForSectionFragmentIds = (sectionFragmentIds: string[], selectedColorId: string) => {
    setSelectedColors(getSelectedColorStateForSectionFragmentIds(sectionFragmentIds, selectedColorId, selectedColors));
  }

  useEffect(() => {
    selectedColorChanged(selectedColors);
  }, [selectedColorChanged, selectedColors]);


  if (returnEmpty) {
    return <></>;
  }

  return (
    <>
      {dropdownData.map(({sectionFragmentIds, materialOptions, sectionFragments}, index) => {
        let groupButton: (() => void) | null = null;
        let groupButtonWillCauseReGroup = true;
        let currentSectionFragmentType: SectionFragmentType | null = null;
        let currentSectionFragmentTypeNameString: string | null = null;

        let sectionFragmentNames = '';
        if (typePrefix === undefined) {
          const sectionFragmentArray: SectionFragmentDto[] = sectionFragments;
            // sectionFragmentIds
            // .map(sfId => sectionFragments.find(sf => sf.id === sfId))
            // .filter(x => !!x)
            // .map(x => x as SectionFragmentDto);

          const sectionFragmentTypes = [...new Set(sectionFragmentArray
            .map(x => x.sectionFragmentType))];


          if (
            sectionFragmentTypes.length >= 1 &&
            // Intersection
            sectionFragmentTypes.some(type => deGroupableTypes.includes(type))
          ) {
            currentSectionFragmentType = sectionFragmentTypes[0];

            currentSectionFragmentTypeNameString = SectionFragmentType[currentSectionFragmentType as unknown as keyof typeof SectionFragmentType].toString()

            groupButtonWillCauseReGroup = deGroupedTypes.indexOf(currentSectionFragmentType) !== -1;

            groupButton = () => {

              let newDegroupedTypes = [
                ...new Set([
                  ...deGroupedTypes,
                  currentSectionFragmentType!
                ])
              ];

              if (groupButtonWillCauseReGroup) {
                newDegroupedTypes = newDegroupedTypes.filter(x => x !== currentSectionFragmentType);
              }

              setGroupedTypes(newDegroupedTypes);
            }
          }

          let sectionFragmentNameArray: string[] = sectionFragmentArray
            .map(e => {
              if(e?.sectionFragmentType === SectionFragmentType.Cheeks) {
                return t('Wangdeel ' + SectionFragmentSide[(e as CheeksDto).cheekSide as unknown as keyof typeof SectionFragmentSide]
                )
              } else if(e?.sectionFragmentType === SectionFragmentType.Stubbed) {
                return t('Boeideel ' + SectionFragmentSide[(e as StubbedDto).stubbedSide as unknown as keyof typeof SectionFragmentSide]
                )
              } else if(e?.sectionFragmentType === SectionFragmentType.FrameSurroundingRod) {
                return t('Kozijn roedes')
              } else if(e.sectionFragmentType === SectionFragmentType.Frame && (e as FrameDto)?.variation === FrameVariation.Window) {
                return null;
              }
              return (e as any)?.name ?? null;
            }).filter(x=> x != null);
          sectionFragmentNameArray = [...new Set(sectionFragmentNameArray)].filter(x => x != null && x.length !== 0);


          if (sectionFragmentNameArray.length > 1) {
            const andTranslationKey = t('and')

            // Join all elements with ', ' except the last element
            const allButLast = sectionFragmentNameArray.slice(0, -1).join("', '");
            // Concatenate the last element with ' and '
            sectionFragmentNames = t(`'${allButLast}', ${andTranslationKey} '${sectionFragmentNameArray.at(-1)}'`);
          } else if (sectionFragmentNameArray.length === 1) {
            sectionFragmentNames = `'${sectionFragmentNameArray[0]}'`;
          }
        }

        const ddTitle = `${t('Material')} ${typePrefix != undefined ? typePrefix : sectionFragmentNames}`;

        return (
          <Dropdown key={`dd-group-${index}`}
                    title={ddTitle}
          >
            <Conditional condition={groupButton != null}>
              <div style={{paddingBottom: '10px'}}>
                <Button
                  size={'small'}
                  style={{
                    backgroundColor: '#f1f1f1',
                    color: 'black',
                  }}
                  as={<div/>}
                  onClick={() => {
                    groupButton && groupButton();
                  }}
                >
                  {groupButtonWillCauseReGroup ? t(`Voeg ${currentSectionFragmentTypeNameString} samen`) : t(`${currentSectionFragmentTypeNameString} individueel instellen`)}
                </Button>
              </div>
            </Conditional>
            <OptionList
              items={materialOptions.flatMap(material => material.colors.map(color => ({
                id: color.colorId,
                name: color.displayName,
                imageSvg: color.imageUrl ?
                  <img height={30} width={30} src={color.imageUrl} alt={`${color.displayName} ${color.colorCode}`}
                       style={{backgroundColor: `#${color.colorCode}`}}/> :
                  <div style={{width: '30px', height: '30px', backgroundColor: `#${color.colorCode}`}}/>,
                subTitle: material.displayName,
              })))}
              selectedItem={selectedColors[sectionFragmentIds[0]]} // Using the first sectionFragmentId to track selection
              setSelectedItem={(selectedColorId) => {
                setSelectedColorStateForSectionFragmentIds(sectionFragmentIds, selectedColorId!);
                // // Updating selectedColors for all sectionFragmentIds in this group
                // const updatedColors = { ...selectedColors };
                // sectionFragmentIds.forEach(sfId => {
                //   updatedColors[sfId] = selectedColorId;
                // });
                // setSelectedColors(updatedColors);
              }}
              primaryColor={clientInformation?.primaryColor}
            />
          </Dropdown>
        )
      })}
    </>
  );
}


const getAvailableDormerMaterials = (sectionFragment: SectionFragmentDto): DormerMaterialViewModel[] => {
  const result = (sectionFragment.copiedFrom?.availableDormerMaterials?.length ?? 0) >= (sectionFragment.availableDormerMaterials?.length ?? 0) ? sectionFragment.copiedFrom?.availableDormerMaterials : sectionFragment.availableDormerMaterials;
  return result ?? [];
}
