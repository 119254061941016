import {put, select, takeLatest} from "redux-saga/effects";
import {updateSelectedUniqueGroupSectionFragmentAction, updateSelectedRoofTrimAction} from "./SectionFragmentActions";
import {
  debouncePutOrderAction,
  DormerRootState,
  getFaceConfiguration, getSelectableFrameOverlaySelector,
  getSelectableRoofTrimSelector,
  getSelectedConfigurationTemplateSelector,
  IMapRequest,
  mapSectionFragmentToUserSectionFragment, replaceOrderSectionFragmentsAction,
  replaceOrderSectionFragmentsReducer, SectionFragmentMutateViewModel,
  storeErrorAction
} from "../../";
import {
  ConfigurationTemplateDto,
  FaceConfigurationDto, FrameDto, FrameOverlaysDto,
  OrderDto,
  RoofTrimDto, SectionFragmentDto,
  SectionFragmentType
} from "@hec/api-dtos";
import {getSouthFaceFromOrder} from "./Util";

function* updateSelectedRoofTrimSaga(action: ReturnType<typeof updateSelectedRoofTrimAction>) {
  const rootstate: DormerRootState = yield select((state: DormerRootState) => state);
  const selectedConfigurationTemplate: ConfigurationTemplateDto | null = yield select(getSelectedConfigurationTemplateSelector);
  const order: OrderDto | null = rootstate.order.order;
  if (order === null) {
    yield put(storeErrorAction({
      error: new Error('No order found'),
    }));
    return;
  }
  const faceConfiguration: FaceConfigurationDto | null = getFaceConfiguration(rootstate);
  if (faceConfiguration === null) {
    yield put(storeErrorAction({
      error: new Error('No faceConfiguration found'),
    }));
    return;
  }

  const selectableRoofTrim: RoofTrimDto[] = getSelectableRoofTrimSelector(rootstate)
  const selected: RoofTrimDto = selectableRoofTrim.find(x => x.id === action.payload)!;

  const southFace = getSouthFaceFromOrder(order);
  const sectionFragments = (southFace?.sectionFragments ?? []).filter(x=> x.sectionFragmentType !== SectionFragmentType.RoofTrim);

  const mapRequest: IMapRequest = {
    selectedConfigurationTemplate: selectedConfigurationTemplate,
    templateSectionFragment: selected,
    order: order,
    sort: sectionFragments.length + 10
  }
  const newRoofTrimSectionFragment:SectionFragmentDto = mapSectionFragmentToUserSectionFragment(mapRequest) as SectionFragmentDto;

  const updatedSectionFragments: SectionFragmentMutateViewModel[] = [
    ...sectionFragments.map(sf => {
      return {...sf, dirty: false}
    }),
    {...newRoofTrimSectionFragment, dirty: true},
  ];


  const newSectionFragmentIds = updatedSectionFragments.filter(x=> x.id != null).map(x=> x.id);
  const duplicatIdSectionFragments = updatedSectionFragments.filter(x=> newSectionFragmentIds.filter(id => x.id == id).length > 1);

  if(duplicatIdSectionFragments.length > 0) {
    console.error("DUPLICATE ID REPLACEMENT ERR!");
  }


  yield put(replaceOrderSectionFragmentsAction({sectionFragments: updatedSectionFragments}));
  yield put(debouncePutOrderAction());
}

function* updateSelectedUniqueGroupSectionFragmentSaga(action: ReturnType<typeof updateSelectedUniqueGroupSectionFragmentAction>) {
  const rootstate: DormerRootState = yield select((state: DormerRootState) => state);
  const selectedConfigurationTemplate: ConfigurationTemplateDto | null = yield select(getSelectedConfigurationTemplateSelector);
  const order: OrderDto | null = rootstate.order.order;
  if (order === null) {
    yield put(storeErrorAction({
      error: new Error('No order found'),
    }));
    return;
  }
  const faceConfiguration: FaceConfigurationDto | null = getFaceConfiguration(rootstate);
  if (faceConfiguration === null) {
    yield put(storeErrorAction({
      error: new Error('No faceConfiguration found'),
    }));
    return;
  }

  const selectableFrameOverlay: FrameOverlaysDto[] = getSelectableFrameOverlaySelector(rootstate);
  // TODO: make unselectable
  const selected: FrameOverlaysDto = selectableFrameOverlay.find(x => x.id === action.payload.id)!;


  const southFace = getSouthFaceFromOrder(order);


  if(action.payload.deselect === true) {

    const before = (southFace?.sectionFragments ?? []).filter(x=> x.name === "Inzet hor")
    const sectionFragments = (southFace?.sectionFragments ?? []).filter(x=> x.uniqueGroup == null || x.uniqueGroup !== selected.uniqueGroup);

    const updatedSectionFragments: SectionFragmentMutateViewModel[] = [
      ...sectionFragments.map(frameOverlay => {
        return {...frameOverlay, dirty: false}
      }),
      // {...selected, dirty: true, deleteMe: true},
    ];

    const example = updatedSectionFragments.filter(x=> x.name === "Inzet hor")

    yield put(replaceOrderSectionFragmentsAction({sectionFragments: updatedSectionFragments}));
    yield put(debouncePutOrderAction());
  } else {
    const mapRequest: IMapRequest = {
      selectedConfigurationTemplate: selectedConfigurationTemplate,
      templateSectionFragment: selected,
      order: order,
      // Will be determined by the backend, this is really a request to add (multiple) frameoverlay(s)
      sort: 9999
    }

    const newFrameOverlaySectionFragment:SectionFragmentDto = mapSectionFragmentToUserSectionFragment(mapRequest) as SectionFragmentDto;

    const sectionFragments = (southFace?.sectionFragments ?? []).filter(x=> x.uniqueGroup == null || x.uniqueGroup !== newFrameOverlaySectionFragment.uniqueGroup);

    const updatedSectionFragments: SectionFragmentMutateViewModel[] = [
      ...sectionFragments.map(frameOverlay => {
        return {...frameOverlay, dirty: false}
      }),
      {...newFrameOverlaySectionFragment, dirty: true},
    ];

    yield put(replaceOrderSectionFragmentsAction({sectionFragments: updatedSectionFragments}));
    yield put(debouncePutOrderAction());
  }


}


export function* SectionFragmentSagas() {
  yield takeLatest(updateSelectedRoofTrimAction, updateSelectedRoofTrimSaga);
  yield takeLatest(updateSelectedUniqueGroupSectionFragmentAction, updateSelectedUniqueGroupSectionFragmentSaga);
}
