
import React, {FunctionComponent} from "react";
import {FrameDto, FrameVariation} from "@hec/api-dtos";
import {degreesToRadians, fromMmToRenderingMeters} from "@hec/core";
import {Conditional, GroupHelper} from "@hec/components/v2";
import {FrameHandle} from "./FrameHandle";
import {OPENING_FRAME_ROD, FRAME_SURROUNDING_ROD_THICKNESS} from "../../../../constants";
import {DormerColorMaterial} from "../DormerColorMaterial";
import {Box} from "@react-three/drei";
import {Base3dProps} from "../Base3dProps";
import {MetalMaterial} from "./Materials";

interface SingularFrameProps extends Base3dProps {
  frame: FrameDto
  amountOfFrames: number;
}

const _randomColor = false;

export const SingularCenterPivotWindowTypeFrame: FunctionComponent<SingularFrameProps> = ({frame, amountOfFrames, visualizationSettings }: SingularFrameProps) => {
  const frameSurroundingRodThickness = fromMmToRenderingMeters(visualizationSettings.sizeFrameSurroundingRod);

  const frameRabbetSideThickness = fromMmToRenderingMeters(OPENING_FRAME_ROD)
  const openingFrameRodDepth = frameRabbetSideThickness;
  const openingFrameRodDepthBottomSubtractor = 0.008;
  const openingFrameRodDepthBottom = frameRabbetSideThickness - openingFrameRodDepthBottomSubtractor;

  const heightAmountToSubtractForSurroundingRods = 0;//frameSurroundingRodThickness * 2;
  const widthAmountToSubtractForSurroundingRods = 0;//((frameSurroundingRodThickness / amountOfFrames) * 2);

  const frameHeight = fromMmToRenderingMeters(frame?.height ?? 0) - heightAmountToSubtractForSurroundingRods;
  const frameWidth = fromMmToRenderingMeters(frame?.width ?? 0) - widthAmountToSubtractForSurroundingRods;

  const verticalIntersectionSpacing = 0.004;
  const verticalRodHeight = (frameHeight) / 2 -verticalIntersectionSpacing;
  const yVerticalRodOffsetBottom = 0;
  const yVerticalRodOffsetTop = verticalRodHeight + yVerticalRodOffsetBottom + verticalIntersectionSpacing;



  const windowPreventClippingOffset = 0.0001;
  const windowHeight = frameHeight - windowPreventClippingOffset;
  const windowWidth = frameWidth - windowPreventClippingOffset;
  const windowDepth = 0.001;

  const variation: FrameVariation = frame.variation!;


  const yOffset = heightAmountToSubtractForSurroundingRods / 2;

  const depthOffset = variation === FrameVariation.Window ? visualizationSettings.offsetFrameDepthWindowVariation : visualizationSettings.offsetFrameDepthNonWindowVariation ;

  const material = <MetalMaterial/>


  // Is really radius or c-section, not sure of nomaclature
  const gripWidth = 0.025;
  const gripDistanceFromSides =  gripWidth * 4;

  const gripBarWidth = (frameWidth - gripDistanceFromSides);


  return <group position={[0, 0, depthOffset]}>
    <group position={[0, yOffset, openingFrameRodDepth]}>
    {/*<mesh position={[frameWidth / 2,frameHeight,0]}>*/}
    {/*  <boxGeometry args={[frameWidth,frameHeight, 0.01]}/>*/}
    {/*</mesh>*/}


      <GroupHelper showHelper={false} key={`frame-${frame.sort}-left-top`} position={[0, 0, -(openingFrameRodDepth / 2)]}>
        <mesh castShadow={true} receiveShadow={true} position={[(frameRabbetSideThickness / 2), yVerticalRodOffsetTop + (verticalRodHeight / 2), 0]}>
          <Box
            args={[frameRabbetSideThickness, verticalRodHeight, openingFrameRodDepth]}
            receiveShadow
            castShadow
          >
            <DormerColorMaterial
              dormerColor={frame?.configuredDormerColor}
              width={frameRabbetSideThickness}
              height={verticalRodHeight}
              randomColor={_randomColor}
            />
          </Box>
        </mesh>
      </GroupHelper>
      <group key={`frame-${frame.sort}-left-gap`}
             position={[0, 0, -((openingFrameRodDepth - verticalIntersectionSpacing) / 2)]}>
        <mesh
          position={[(frameRabbetSideThickness / 2), (yVerticalRodOffsetTop - verticalIntersectionSpacing / 2), -verticalIntersectionSpacing]}>
          <Box
            args={[frameRabbetSideThickness, verticalIntersectionSpacing, openingFrameRodDepth - verticalIntersectionSpacing]}
            receiveShadow
            castShadow
          >
            <DormerColorMaterial
              dormerColor={frame?.configuredDormerColor}
              width={frameRabbetSideThickness}
              height={verticalIntersectionSpacing}
              randomColor={_randomColor}
            />
          </Box>
        </mesh>
      </group>
      <group key={`frame-${frame.sort}-left-bottom`} position={[0, 0, -(openingFrameRodDepth / 2)]}>
        <mesh castShadow={true} receiveShadow={true} position={[(frameRabbetSideThickness / 2), yVerticalRodOffsetBottom + (verticalRodHeight / 2), 0]}>
          <Box
            args={[frameRabbetSideThickness, verticalRodHeight, openingFrameRodDepth]}
            receiveShadow
            castShadow
          >
            <DormerColorMaterial
              dormerColor={frame?.configuredDormerColor}
              width={frameRabbetSideThickness}
              height={verticalRodHeight}
              randomColor={_randomColor}
            />
          </Box>
        </mesh>
      </group>


      <group key={`frame-${frame.sort}-right-top`} position={[frameWidth, 0, -(openingFrameRodDepth / 2)]}>
        <mesh castShadow={true} receiveShadow={true} position={[-(frameRabbetSideThickness / 2), yVerticalRodOffsetTop + (verticalRodHeight / 2), 0]}>
          <Box
            args={[frameRabbetSideThickness, verticalRodHeight, openingFrameRodDepth]}
            receiveShadow
            castShadow
          >
            <DormerColorMaterial
              dormerColor={frame?.configuredDormerColor}
              width={frameRabbetSideThickness}
              height={verticalRodHeight}
              randomColor={_randomColor}
            />
          </Box>
        </mesh>
      </group>
      <group key={`frame-${frame.sort}-right-gap`}
             position={[frameWidth, 0, -((openingFrameRodDepth - verticalIntersectionSpacing) / 2)]}>
        <mesh
          position={[-(frameRabbetSideThickness / 2), (yVerticalRodOffsetTop - verticalIntersectionSpacing / 2), -verticalIntersectionSpacing]}>
          <Box
            args={[frameRabbetSideThickness, verticalIntersectionSpacing, openingFrameRodDepth - verticalIntersectionSpacing]}
            receiveShadow
            castShadow
          >
            <DormerColorMaterial
              dormerColor={frame?.configuredDormerColor}
              width={frameRabbetSideThickness}
              height={verticalIntersectionSpacing}
              randomColor={_randomColor}
            />
          </Box>
        </mesh>
      </group>
      <group key={`frame-${frame.sort}-right-bottom`} position={[frameWidth, 0, -(openingFrameRodDepth / 2)]}>
        <mesh castShadow={true} receiveShadow={true} position={[-(frameRabbetSideThickness / 2), yVerticalRodOffsetBottom + (verticalRodHeight / 2), 0]}>
          <Box
            args={[frameRabbetSideThickness, verticalRodHeight, openingFrameRodDepth]}
            receiveShadow
            castShadow
          >
            <DormerColorMaterial
              dormerColor={frame?.configuredDormerColor}
              width={frameRabbetSideThickness}
              height={verticalRodHeight}
              randomColor={_randomColor}
            />
          </Box>
        </mesh>
      </group>

      <mesh
        position={[gripDistanceFromSides / 2 + gripBarWidth / 2, frameHeight - (frameRabbetSideThickness / 2 + gripWidth / 2), -(openingFrameRodDepth * 2)]}
        rotation={[0, 0, Math.PI / 2]}>
        <cylinderGeometry
          args={[gripWidth / 2, gripWidth / 2, gripBarWidth]}
        >
        </cylinderGeometry>
        {material}
      </mesh>

      <group key={`frame-${frame.sort}-top`} position={[0, frameHeight, 0]}>
        <mesh castShadow={true} receiveShadow={true} position={[frameWidth / 2, -(frameRabbetSideThickness / 2), -(openingFrameRodDepth / 2)]}>
          <Box
            args={[frameWidth, frameRabbetSideThickness, openingFrameRodDepth]}
            receiveShadow
            castShadow
          >
            <DormerColorMaterial
              dormerColor={frame?.configuredDormerColor}
              width={frameWidth}
              height={frameRabbetSideThickness}
              randomColor={_randomColor}
            />
          </Box>
        </mesh>
      </group>

      <group
        key={`frame-${frame.sort}-top-above`} position={[0, frameHeight, openingFrameRodDepth / 2]}
        rotation={[(degreesToRadians(15)), 0, 0]}
      >
        <mesh castShadow={true} receiveShadow={true} position={[frameWidth / 2, -(frameRabbetSideThickness / 2) - 0.010, -(openingFrameRodDepth / 4)]}>
          <Box
            args={[frameWidth, frameRabbetSideThickness, openingFrameRodDepth / 2]}
            receiveShadow
            castShadow
          >
            <DormerColorMaterial
              dormerColor={frame?.configuredDormerColor}
              width={frameWidth}
              height={frameRabbetSideThickness}
              randomColor={_randomColor}
            />
          </Box>
        </mesh>
      </group>


      <group key={`frame-${frame.sort}-bottom`} position={[0, frameRabbetSideThickness, 0]}>
        <mesh castShadow={true} receiveShadow={true} position={[(frameWidth) / 2, -(frameRabbetSideThickness / 2), -(openingFrameRodDepthBottom / 2) - openingFrameRodDepthBottomSubtractor]}>
          <Box
            args={[frameWidth - frameRabbetSideThickness * 2, frameRabbetSideThickness, openingFrameRodDepthBottom]}
            receiveShadow
            castShadow
          >
            <DormerColorMaterial
              dormerColor={frame?.configuredDormerColor}
              width={frameWidth}
              height={frameRabbetSideThickness}
              randomColor={_randomColor}
            />
          </Box>
        </mesh>
      </group>

      {/* WINDOW */}
      <group key={'window'}>

        <mesh castShadow={true} receiveShadow={true} position={[windowWidth / 2, windowHeight / 2, -(openingFrameRodDepth / 2)]}>
          <boxGeometry
            args={[windowWidth, windowHeight, windowDepth]}
          />
          <meshPhysicalMaterial
            color="#add8e6"
            transmission={0.9}  // controls the transparency
            roughness={0}       // a smoother surface for a clearer glass
            reflectivity={0.9}  // high reflectivity for glass-like appearance
            clearcoat={1}       // adds a clear coating on the material
            clearcoatRoughness={0} // smooth clearcoat for a more realistic glass
            // you can add more properties as needed
          />
        </mesh>
      </group>
    </group>

  </group>
}


