import {
  ClientInformationState,
  ConfigurationTemplateState,
  DormerMaterialsState,
  LoggingState,
  NotificationState, OrderInvoiceState,
  OrderState,
  SelectableSectionFragmentsState,
  ServiceState, SurveyQuestionsState,
  UiStatusState
} from ".";

export interface DormerRootState {
  clientInformation: ClientInformationState;
  configurationTemplates: ConfigurationTemplateState;
  dormerMaterials: DormerMaterialsState;
  logging: LoggingState;
  notifications: NotificationState;
  order: OrderState;
  orderInvoice: OrderInvoiceState;
  selectableSectionFragments: SelectableSectionFragmentsState;
  services: ServiceState;
  uiStatus: UiStatusState;
  surveyQuestions: SurveyQuestionsState;
  // clientConfiguration
}
