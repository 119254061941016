import {SectionFragmentDto} from "@hec/api-dtos";

export interface SelectableSectionFragmentsState {
  selectableSectionFragments: SectionFragmentDto[];
}

export const selectableSectionFragmentsState: SelectableSectionFragmentsState = {
  selectableSectionFragments: [],
}

