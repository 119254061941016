import {createAction} from "@reduxjs/toolkit";
import {
  DormerColorViewModel,
  FaceConfigurationSectionFragmentCombinationDto,
  FrameDto,
  SectionFragmentDto
} from "@hec/api-dtos";

interface PrepareAddNewFrameActionProps {
  sort: number;
}
export const prepareAddNewFrameAction = createAction<PrepareAddNewFrameActionProps>('prepareAddNewFrame');
type AddNewFrameActionProps = FrameDto
export const addNewFrameAction = createAction<AddNewFrameActionProps>('addNewFrame');
interface RemoveFrameActionProps {
  sort: number;
}
export const removeFrameAction = createAction<RemoveFrameActionProps>('removeFrame');

interface PrepareChangeFrameCopiedFromActionProps {
  sort: number;
  copiedFromId: string;
}
export const prepareChangeFrameCopiedFromAction = createAction<PrepareChangeFrameCopiedFromActionProps>('prepareChangeFrameCopiedFrom');

type UpdateFrameActionProps = FrameDto
// export const updateFrameAction = createAction<UpdateFrameActionProps>('updateFrame');

interface SwapFrameOrderActionProps {
  sort1: number;
  sort2: number;
}
export const swapFrameOrderAction = createAction<SwapFrameOrderActionProps>('swapFrameOrder');

interface PrepareUpdateFrameSizeActionProps {
  sort: number;
  width: number | null;
  height: number | null;
}
export const prepareUpdateFrameSizeAction = createAction<PrepareUpdateFrameSizeActionProps>('prepareUpdateFrameSize');

interface UpdateFrameSizeActionProps {
  sort: number;
  width: number;
  height: number;
}
export const updateFrameSizeAction = createAction<UpdateFrameSizeActionProps>('updateFrameSize');


export enum DormerColorType {
  normal,
  sideRods
}

export interface UpdateSectionFragmentDormerColorActionProps {
  id: string;
  dormerColorType?: DormerColorType;
  color: DormerColorViewModel;
}

export interface UpdateSectionFragmentDormerColorsActionProps {
  changes: UpdateSectionFragmentDormerColorActionProps[];
}
export const updateSectionFragmentDormerColorsAction = createAction<UpdateSectionFragmentDormerColorsActionProps>('updateSectionFragmentDormerColorsAction');

interface FrameTemplateSelectedActionProps {
  selectedFaceConfigurationSectionFragmentCombinationDto: FaceConfigurationSectionFragmentCombinationDto | null
}

export const frameTemplateSelectedAction = createAction<FrameTemplateSelectedActionProps>('frameTemplateSelectedAction');



export interface SectionFragmentMutateViewModel extends SectionFragmentDto {
  dirty: boolean;
  deleteMe?: boolean
}

interface replaceOrderSectionFragmentsActionProps {
  sectionFragments: SectionFragmentMutateViewModel[];
}
export const replaceOrderSectionFragmentsAction = createAction<replaceOrderSectionFragmentsActionProps>('replaceOrderSectionFragmentsAction');


interface replaceOrderSectionFragmentsActionProps {
  sectionFragments: SectionFragmentMutateViewModel[];
}
export const shrinkToSizeConfigurationToMinimumSizeRequiredForFramesAction = createAction('shrinkToSizeConfigurationToMinimumSizeRequiredForFramesAction');
