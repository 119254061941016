import {
  addNewFrameAction,
  frameTemplateSelectedAction,
  prepareAddNewFrameAction,
  prepareChangeFrameCopiedFromAction,
  prepareUpdateFrameSizeAction,
  replaceOrderSectionFragmentsAction,
  SectionFragmentMutateViewModel,
  updateSectionFragmentDormerColorsAction
} from "./FrameActions";
import {
  CheeksDto,
  ConfigurationTemplateDto,
  FaceConfigurationDto,
  FaceConfigurationSectionFragmentCombinationDefinitionDto,
  FaceDto,
  FrameDto,
  FrameVariation,
  OrderDto,
  SectionFragmentDto,
  SectionFragmentType,
  Side,
  SplinterDto,
  StubbedDto
} from "@hec/api-dtos";
import {put, select, takeLatest} from "redux-saga/effects";
import {storeErrorAction} from "../Logging";
import {
  debouncePutOrderAction,
  getCheeks,
  getDormerTotalWidth,
  getFaceConfiguration,
  getOrderSelector,
  getSouthFaceSelector,
  getSplinter,
  getStubbed,
  updateMeasurementsAction
} from "./";
import {getDefaultFrameSelector} from "../SelectableSectionFragments";
import {generateGuid, getSelectableSectionFragmentById, getSouthFaceFromOrder} from "./Util";
import {DormerRootState} from "../DormerRootState";
import {getCheeksWidth, getSplinterHeight, getSplinterWidth, getStubbedHeight} from "@hec/core";
import {getSelectedConfigurationTemplateSelector} from "../ConfigurationTemplates";
import {makeInspectable} from "./Util/MakeSectionFragmentsInspectable";

function* AddNewFrameSaga(action: ReturnType<typeof prepareAddNewFrameAction>) {
  const southFace: FaceDto | null = yield select(getSouthFaceSelector);
  const order: OrderDto | null = yield select(getOrderSelector);
  const defaultFrame: FrameDto | null = yield select(getDefaultFrameSelector);
  const faceConfiguration: FaceConfigurationDto | null = yield select(getFaceConfiguration);
  const splinter: SplinterDto | null = yield select(getSplinter);
  const stubbed: StubbedDto | null = yield select(getStubbed);
  const cheeks: CheeksDto | null = yield select(getCheeks);
  const selectedConfigurationTemplate: ConfigurationTemplateDto | null = yield select(getSelectedConfigurationTemplateSelector);

  if (southFace === null || order === null || defaultFrame === null || faceConfiguration === null || splinter === null || stubbed === null || cheeks === null) {
    yield put(storeErrorAction({
      error: new Error('No all requirements met'),
    }));
    return;
  }
  const mapRequest: IMapRequest = {
    selectedConfigurationTemplate: selectedConfigurationTemplate,
    templateSectionFragment: defaultFrame,
    order: order,
    sort: action.payload.sort
  }

  const addFramePayload = mapSectionFragmentToUserSectionFragment(mapRequest);
  yield put(addNewFrameAction(addFramePayload as FrameDto));

  // Update dormer width
  const totalDormerWidth: number | null = yield select(getDormerTotalWidth);
  if (totalDormerWidth !== null && totalDormerWidth > faceConfiguration.width!) {
    yield put(updateMeasurementsAction({width: totalDormerWidth, height: faceConfiguration.height}));
  }

  yield put(debouncePutOrderAction());
}

function IsSameSectionFragment(sf1: SectionFragmentDto, sf2: SectionFragmentDto): boolean {
  return (sf1.tempId !== null && sf1.tempId === sf2.tempId) ||
    (sf1.id !== null && sf1.id === sf2.id);
}

function* prepareChangeFrameCopiedFrom(action: ReturnType<typeof prepareChangeFrameCopiedFromAction>) {
  const rootstate: DormerRootState = yield select((state: DormerRootState) => state);

  const order: OrderDto | null = rootstate.order.order;

  if (order === null) {
    yield put(storeErrorAction({
      error: new Error('No order found'),
    }));
    return;
  }
  const faceConfiguration: FaceConfigurationDto | null = getFaceConfiguration(rootstate);
  if (faceConfiguration === null) {
    yield put(storeErrorAction({
      error: new Error('No faceConfiguration found'),
    }));
    return;
  }

  const southFace = getSouthFaceFromOrder(order);
  const sectionFragments = southFace?.sectionFragments ?? [];

  const sourceFrame: FrameDto | undefined = sectionFragments
    .find(frame =>
      frame.sectionFragmentType === SectionFragmentType.Frame &&
      frame.sort === action.payload.sort) as FrameDto | undefined;

  if (!sourceFrame) {
    yield put(storeErrorAction({
      error: new Error('No sourceFrame found'),
    }));
    return;
  }

  const selectableFrame: FrameDto | undefined = getSelectableSectionFragmentById(rootstate, action.payload.copiedFromId) as FrameDto | undefined;

  if (!selectableFrame) {
    yield put(storeErrorAction({
      error: new Error('No selectableFrame found'),
    }));
    return;
  }

  const getWidth = () => {
    if (sourceFrame.width != null && selectableFrame.maxWidth != null && sourceFrame.width < selectableFrame.maxWidth) {
      if (selectableFrame.minWidth != null && sourceFrame.width > selectableFrame.minWidth) {
        return sourceFrame.width;
      }
    }

    return selectableFrame.width;
  }


  const template = getSelectedConfigurationTemplateSelector(rootstate);

  const mapRequest: IMapRequest = {
    selectedConfigurationTemplate: template,
    templateSectionFragment: selectableFrame,
    order: order,
    sort: action.payload.sort,
    existingUserOwnedSectionFragment: sourceFrame,
    overrideId: null,
    width: getWidth(),
  }

  const updatedFrame = mapSectionFragmentToUserSectionFragment(mapRequest) as FrameDto;

  const sfToKeep = sectionFragments
    .filter(frame => !IsSameSectionFragment(frame, sourceFrame));

  const updatedSectionFragments: SectionFragmentMutateViewModel[] = [
    {...updatedFrame, dirty: true},
    ...sfToKeep
      .map(frame => {
        return {...frame, dirty: false}
      })
  ];

  yield put(replaceOrderSectionFragmentsAction({sectionFragments: updatedSectionFragments}));

  // Update dormer width
  const totalDormerWidth: number | null = yield select(getDormerTotalWidth);
  if (totalDormerWidth !== null && totalDormerWidth > faceConfiguration.width!) {
    yield put(updateMeasurementsAction({width: totalDormerWidth, height: faceConfiguration.height}));
  }
  yield put(debouncePutOrderAction());
}

export interface IMapRequest {
  selectedConfigurationTemplate: ConfigurationTemplateDto | null,
  templateSectionFragment: SectionFragmentDto,
  order: OrderDto,
  sort?: number | undefined,
  existingUserOwnedSectionFragment?: SectionFragmentDto | undefined,
  faceSide?: Side,
  overrideId?: string | undefined | null,
  width?: number | undefined
}


export const mapSectionFragmentToUserSectionFragment = (request: IMapRequest) => {
  const {
    selectedConfigurationTemplate,
    templateSectionFragment,
    order,
    sort,
    existingUserOwnedSectionFragment,
    faceSide = Side.South,
    overrideId,
    width
  } = request;

  const face = order.faceConfiguration.faces.find(face => face.side == faceSide);
  const faceId = face?.id;
  if (!faceId) {
    throw new Error('No south face found');
  }

  const faceConfigurationHeight = order?.faceConfiguration.height ?? selectedConfigurationTemplate?.defaultHeight;

  const id = overrideId === null ? null : existingUserOwnedSectionFragment?.id ?? null;

  const newSf: SectionFragmentDto = {
    ...templateSectionFragment,
    id,
    tempId: existingUserOwnedSectionFragment?.tempId ?? generateGuid(),
    copiedFrom: templateSectionFragment,
    copiedFromId: templateSectionFragment.id,
    sort: sort ?? existingUserOwnedSectionFragment?.sort ?? templateSectionFragment.sort,
    organisationId: null,
    orderId: order.id,
    configurationTemplateId: null,
    faceConfigurationId: order.faceConfiguration.id,
    isOrganisationAddable: false,
    isDefaultForTemplate: false,
    isUserSelectable: false,
    belongsToUser: true,
    faceId: faceId
  }

  if (width) {
    newSf.width = width;
  }


  if (newSf.sectionFragmentType === SectionFragmentType.Frame) {
    (newSf as FrameDto).isSelectableFrame = false;

    if (faceConfigurationHeight != null && face?.sectionFragments && faceConfigurationHeight && faceConfigurationHeight > 0) {
      if (newSf.height) {
        const reservedHeight = getSplinterHeight(face) * 2 + getStubbedHeight(face);

        const desiredHeight = faceConfigurationHeight - reservedHeight;

        if (newSf.sectionFragmentType === SectionFragmentType.Frame && newSf.height !== desiredHeight) {
          newSf.height = desiredHeight;
        }
      }
    }
  }

  return newSf;
}

function* prepareUpdateFrameSize(action: ReturnType<typeof prepareUpdateFrameSizeAction>) {
  const order: OrderDto | null = yield select(getOrderSelector);
  if (!order) {
    yield put(storeErrorAction({
      error: new Error('No order found'),
    }));
    return;
  }
  const southFace = getSouthFaceFromOrder(order);
  const sectionFragments = southFace?.sectionFragments ?? [];

  const oldFrame: FrameDto | undefined = sectionFragments
    .find(frame =>
      frame.sectionFragmentType === SectionFragmentType.Frame &&
      frame.sort === action.payload.sort) as FrameDto | undefined;

  if (!oldFrame) {
    yield put(storeErrorAction({
      error: new Error('OldFrame not found'),
    }));
    return;
  }
  if (!oldFrame.copiedFrom) {
    yield put(storeErrorAction({
      error: new Error('OldFrame expected to have copiedFrom'),
    }));
    return;
  }

  const selectedConfigurationTemplate: ConfigurationTemplateDto | null = yield select(getSelectedConfigurationTemplateSelector);


  const mapRequest: IMapRequest = {
    selectedConfigurationTemplate: selectedConfigurationTemplate,
    templateSectionFragment: oldFrame.copiedFrom as SectionFragmentDto,
    order: order,
    sort: action.payload.sort,
    existingUserOwnedSectionFragment: oldFrame
  }

  const updatedFrame = mapSectionFragmentToUserSectionFragment(mapRequest) as FrameDto;

  if (action.payload.width) {
    updatedFrame.width = action.payload.width;
  }
  const unmodifiedSectionFragments = sectionFragments
    .filter(frame => (frame.sort !== updatedFrame.sort) || frame.sectionFragmentType !== updatedFrame.sectionFragmentType)
    .map(frame => {
      return {...frame, dirty: false}
    });

  const updatedSectionFragments: SectionFragmentMutateViewModel[] = [
    {...updatedFrame, dirty: true},
    ...unmodifiedSectionFragments
  ];


  yield put(replaceOrderSectionFragmentsAction({sectionFragments: updatedSectionFragments}));

  // Update dormer width
  const totalDormerWidth: number | null = yield select(getDormerTotalWidth);
  const faceConfiguration: FaceConfigurationDto | null = yield select(getFaceConfiguration);
  if (totalDormerWidth !== null && faceConfiguration !== null) {
    if (totalDormerWidth > faceConfiguration.width!) {
      yield put(updateMeasurementsAction({width: totalDormerWidth, height: null}));
    }
  }
  yield put(debouncePutOrderAction());
}

function* frameTemplateSelectedSaga(action: ReturnType<typeof frameTemplateSelectedAction>) {
  const selectedCombination = action.payload.selectedFaceConfigurationSectionFragmentCombinationDto;

  const definitions: FaceConfigurationSectionFragmentCombinationDefinitionDto[] | undefined = selectedCombination?.faceConfigurationSectionFragmentCombinationDefinitions;

  if (!definitions) {
    return;
  }


  const rootstate: DormerRootState = yield select((state: DormerRootState) => state);
  const selectedConfigurationTemplate: ConfigurationTemplateDto | null = getSelectedConfigurationTemplateSelector(rootstate);

  const order: OrderDto | null = rootstate.order.order;
  if (!order) {
    return;
  }

  const southFace = getSouthFaceFromOrder(order);
  if (!southFace) {
    return;
  }
  const sectionFragments: SectionFragmentMutateViewModel[] = [];
  const totalwidth = order.faceConfiguration.width ?? 0;


  interface definitionViewModel extends FaceConfigurationSectionFragmentCombinationDefinitionDto {
    sectionFragment: SectionFragmentDto;
  }

  const definitionViewModels: definitionViewModel[] = definitions.map((d) => {
    return {
      ...d,
      sectionFragment: getSelectableSectionFragmentById(rootstate, d.sectionFragmentId),
    } as definitionViewModel;
  });
  if (definitionViewModels.find(x => !x.sectionFragment)) {
    // This is a race condition that can occur on first update of measurements.
    return;
  }


  let reservedWidthFromDefinitions = 0;

  const initialValue = 0;
  const definitionWidths = definitionViewModels
    .filter(x => x.flex == null)
    .map(x => x.sectionFragment?.width ?? 0);
  reservedWidthFromDefinitions = definitionWidths
    .reduce((acc, cv) => acc + cv, initialValue)

  // Combined width of all non flexible sectionfragements.
  const reservedWidth = reservedWidthFromDefinitions +
    getCheeksWidth(southFace) * 2 + getSplinterWidth(southFace) * 2;

  const widthToDevide = totalwidth - reservedWidth;

  const totalFlex = definitionViewModels
    .map(x => x.flex ?? 0)
    .reduce((acc, cv) => acc + cv);


  definitionViewModels.forEach(definition => {
    const sfToConfigure = definition.sectionFragment;


    const mapRequest: IMapRequest = {
      selectedConfigurationTemplate: selectedConfigurationTemplate,
      templateSectionFragment: sfToConfigure,
      order: order,
      sort: definition.index,
      existingUserOwnedSectionFragment: undefined
    }

    const newSf: SectionFragmentDto = mapSectionFragmentToUserSectionFragment(mapRequest)

    if (definition.flex != null) {
      newSf.width = (widthToDevide / totalFlex * definition.flex);
    }

    const newSfMutateModel = {...newSf, dirty: true};

    sectionFragments.push(newSfMutateModel);
  });

  const nonAffected = (southFace?.sectionFragments
    .filter(x => x.sectionFragmentType != SectionFragmentType.Frame) ?? [])
    .map(x => {
      return {...x, dirty: false}
    });

  sectionFragments.push(...nonAffected);

  const newSectionFragmentIds = sectionFragments.filter(x=> x.id != null).map(x=> x.id);
  const duplicatIdSectionFragments = sectionFragments.filter(x=> newSectionFragmentIds.filter(id => x.id == id).length > 1);

  if(duplicatIdSectionFragments.length > 0) {
    console.error("DUPLICATE ID REPLACEMENT ERR!");
  }

  // sectionFragments.forEach((sf) => {
  //   if(sf.sectionFragmentType != SectionFragmentType.Frame && sf.variation != FrameVariation.Panel)
  //   {
  //     return sf;
  //   }
  //   sf.width = (sf.width ?? 0)* 2
  //   return sf;
  // })

  const result = makeInspectable(sectionFragments);

  yield put(replaceOrderSectionFragmentsAction({sectionFragments: sectionFragments}));
  yield put(debouncePutOrderAction());
}

function* putDebouncePutOrderAction() {
  yield put(debouncePutOrderAction());
}

export function* FrameSagas() {
  yield takeLatest(prepareAddNewFrameAction, AddNewFrameSaga);
  yield takeLatest(prepareChangeFrameCopiedFromAction, prepareChangeFrameCopiedFrom);
  yield takeLatest(prepareUpdateFrameSizeAction, prepareUpdateFrameSize);
  yield takeLatest(updateSectionFragmentDormerColorsAction, putDebouncePutOrderAction);
  yield takeLatest(frameTemplateSelectedAction, frameTemplateSelectedSaga);
}
