import produce from 'immer';
import {getOrderSucceededAction, putOrderSucceededAction} from './';
import {OrderState} from './State';
import {getSectionFragmentsFromOrder, getSectionFragmentsFromOrderTracked} from "./Util";
import {DormerColorViewModel, DormerMaterialViewModel, SectionFragmentDto, SectionFragmentType} from "@hec/api-dtos";
import {makeInspectable} from "./Util/MakeSectionFragmentsInspectable";

export const getOrderSucceededReducer = (state: OrderState, action: ReturnType<typeof getOrderSucceededAction>) => {
  return produce(state, (draft) => {
    draft.order = action.payload.order;
  });
}

export const putOrderSucceededReducer = (state: OrderState, action: ReturnType<typeof putOrderSucceededAction>) => {
  return produce(state, (draft) => {
    if (!draft.order) {
      return;
    }

    const mutableSectionFragments = [SectionFragmentType.Frame, SectionFragmentType.RoofTrim, SectionFragmentType.FrameOverlay];
    const orderSectionFragments = getSectionFragmentsFromOrder(draft.order);


    const mutableOldSfs = orderSectionFragments
      .filter(x => mutableSectionFragments.indexOf(x.sectionFragmentType) !== -1);
    const customerImmutableOldSfs = orderSectionFragments
      .filter(x => mutableSectionFragments.indexOf(x.sectionFragmentType) === -1);


    const payloadSfs = getSectionFragmentsFromOrder(action.payload.orderDto)
      .filter(x => mutableSectionFragments.indexOf(x.sectionFragmentType) !== -1);

    const getDedupedMaterialById = (id: string): DormerMaterialViewModel | undefined => {
      const allMaterials = [
        ...mutableOldSfs.flatMap(x => x.availableDormerMaterials),
        ...mutableOldSfs.flatMap(x => x.availableDormerMaterialsSideRods)
      ].filter(x => !!x);
      const result = allMaterials.find(x => x.id === id);

      return result;
    }

    const getDedupedCopiedFromId = (id: string | null | undefined): SectionFragmentDto | null => {
      if (!id) {
        return null;
      }

      const allCopiedFrom = [
        ...mutableOldSfs.flatMap(x => x.copiedFrom),
      ];
      const result = allCopiedFrom.find(x => x?.id === id) ?? null;

      return result;
    }

    const getDedupedColorId = (id: string | null | undefined): DormerColorViewModel | null => {
      if (!id) {
        return null;
      }
      const allMaterials = [
        ...mutableOldSfs.flatMap(x => x.availableDormerMaterials),
        ...mutableOldSfs.flatMap(x => x.availableDormerMaterialsSideRods)
      ].filter(x => !!x);

      const allColors = [
        ...allMaterials.flatMap(x => x.dormerColors),
        ...mutableOldSfs.map(x => x.configuredDormerColor),
        ...mutableOldSfs.map(x => x.configuredDormerColorSideRods)
      ].filter(x => !!x);

      const result = allColors.find(x => x?.id === id) ?? null;

      return result;
    }


    const finalSfs = [
      ...customerImmutableOldSfs,
      ...payloadSfs.map(sf => {
        sf.configuredDormerColor = getDedupedColorId(sf.configuredDormerColorId ?? sf.configuredDormerColor?.id) ?? null;
        sf.configuredDormerColorSideRods = getDedupedColorId(sf.configuredDormerColorSideRodId ?? sf.configuredDormerColorSideRods?.id) ?? null;

        const availableDormerMaterialIds = sf.availableDormerMaterialIds ?? sf.availableDormerMaterials?.map(x => x?.id) ?? [];
        sf.availableDormerMaterials = availableDormerMaterialIds.map(id => getDedupedMaterialById(id)).filter(x => !!x) as DormerMaterialViewModel[];


        const availableDormerMaterialsSideRodIds = sf.availableDormerMaterialsSideRodIds ?? sf.availableDormerMaterialsSideRods?.map(x => x?.id) ?? [];
        sf.availableDormerMaterialsSideRods = availableDormerMaterialsSideRodIds.map(id => getDedupedMaterialById(id)).filter(x => !!x) as DormerMaterialViewModel[];

        // DEDUPE COPIEDFROM

        sf.copiedFrom = getDedupedCopiedFromId(sf.copiedFromId ?? sf.copiedFrom?.id);

        return sf;
      })
    ];

    let draftSfs = getSectionFragmentsFromOrderTracked(draft.order);
    draftSfs = finalSfs;

    draftSfs = draftSfs.sort((prev, next) => prev.sort! - next.sort!)


    const result = makeInspectable(draftSfs)

    if (draftSfs.filter(x => x.sectionFragmentType === SectionFragmentType.FrameOverlay).length > 1) {
      console.log(result);
    }


    /// This is retarted because it should really just track the reference using the line above:
    /// ==> let draftSfs = getSectionFragmentsFromOrderTracked(draft.order);
    /// But that is not the case, I hate javascript with a passion.
    draft.order.faceConfiguration.faces[0].sectionFragments = draftSfs;

    draft.isDirty = false;
  });
}


export const markOrderDirtyReducer = (state: OrderState) => {
  return produce(state, (draft) => {
    draft.isDirty = true;
  });
}
