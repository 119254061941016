import {DormerMaterialViewModel, SectionFragmentDto, SectionFragmentType} from "@hec/api-dtos";
import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import {OptionItem, OptionList} from "../OptionList";
import {Conditional} from "@hec/components/v1";
import {TranslationKeys} from "@hec/models";
import {Dropdown} from "../Dropdown";

interface SelectableSectionFragmentColorSelectorProps {
  colorId: string | null,
  // sectionFragments: SectionFragmentDto[],
  dormerMaterialViewModels: DormerMaterialViewModel[]
  onSelectionChanged: (colorId: string) => void;
  dropdownTitle: string,
}

export const SelectableSectionFragmentColorSelector: FunctionComponent<SelectableSectionFragmentColorSelectorProps> = ({
                                                                                                                         colorId,
                                                                                                                         dormerMaterialViewModels,
                                                                                                                         onSelectionChanged,
                                                                                                                         dropdownTitle
                                                                                                                       }: SelectableSectionFragmentColorSelectorProps) => {
  const selectableColors = useMemo(() => {
    const selectable: OptionItem[] = [];
    dormerMaterialViewModels.forEach(dm => {
      dm.dormerColors.forEach(dc => {
        const item: OptionItem = {
          id: dc.id ?? 'id-is-null-should-not-happen-at-SelectableSectionFragmentColorSelector',
          name: dc.name,
        }

        selectable.push(item)
      })
    });

    return selectable;
  }, [dormerMaterialViewModels]);

  return <>
    <Conditional condition={selectableColors.length > 1}>
      <Dropdown title={dropdownTitle}>
        <OptionList
          items={selectableColors}
          selectedItem={colorId}
          setSelectedItem={(id) => {
            onSelectionChanged(id!);
          }}
        />
      </Dropdown>
    </Conditional>

  </>
}
