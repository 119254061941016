import { Route, TranslationKeys } from '@hec/models';
import { RoutePaths } from './RoutePaths';
import {
  TemplateSideBarContent,
  MeasurementsSideBarContent,
  FramesSideBarContent,
  MaterialSideBarContent,
  ExtrasSideBarContent,
  TechnicalSideBarContent,
  SummarySideBarContent,
  CustomerSurveySideBarContent
} from "../pages";
import {bool} from "yup";



export const Routes: Route[] = [
  {
    name: 'routes.template',
    path: RoutePaths.TemplatePath,
    sideDrawerContent: <TemplateSideBarContent />,
    translationKey: TranslationKeys.dormer.pages.templatePage.name,
    displaySettings: {
      displayInNavigation: true,
      displayTitleInDrawer: true,
      titleNoMarginBottom: true,
    },
    actorDefinedRoute: {
      showToPro:true,
      showToCustomer: true
    }
  },
  {
    name: 'routes.measurements',
    path: RoutePaths.MeasurementsPath,
    sideDrawerContent: <MeasurementsSideBarContent />,
    translationKey: TranslationKeys.dormer.pages.measurementsPage.name,
    displaySettings: {
      displayInNavigation: true,
      displayTitleInDrawer: true,
      titleNoMarginBottom: true,
    },
    actorDefinedRoute: {
      showToPro:true,
      showToCustomer: true
    }
  },
  {
    name: 'routes.frames',
    path: RoutePaths.FramesPath,
    sideDrawerContent: <FramesSideBarContent />,
    translationKey: TranslationKeys.dormer.pages.framesPage.name,
    displaySettings: {
      displayInNavigation: true,
      displayTitleInDrawer: true,
      titleNoMarginBottom: true,
    },
    actorDefinedRoute: {
      showToPro:true,
      showToCustomer: true
    }
  },
  {
    name: 'routes.material',
    path: RoutePaths.MaterialPath,
    sideDrawerContent: <MaterialSideBarContent />,
    translationKey: TranslationKeys.dormer.pages.materialPage.name,
    displaySettings: {
      displayInNavigation: true,
      displayTitleInDrawer: true,
      titleNoMarginBottom: true,
    },
    actorDefinedRoute: {
      showToPro:true,
      showToCustomer: true
    }
  },
  {
    name: 'routes.extras',
    path: RoutePaths.ExtrasPath,
    sideDrawerContent: <ExtrasSideBarContent />,
    translationKey: TranslationKeys.dormer.pages.extrasPage.name,
    displaySettings: {
      displayInNavigation: true,
      displayTitleInDrawer: true,
      titleNoMarginBottom: true,
    }
  },
  {
    name: 'routes.customer-survey',
    path: RoutePaths.CustomerSurveyPath,
    sideDrawerContent: <CustomerSurveySideBarContent />,
    translationKey: TranslationKeys.dormer.pages.customerSurveyPage.name,
    displaySettings: {
      displayInNavigation: true,
      displayTitleInDrawer: true,
      titleNoMarginBottom: true,
    },
    actorDefinedRoute: {
      showToPro:true,
      showToCustomer: true
    }
  },
  {
    name: 'routes.technical',
    path: RoutePaths.TechnicalPath,
    sideDrawerContent: <TechnicalSideBarContent />,
    translationKey: TranslationKeys.dormer.pages.technicalPage.name,
    displaySettings: {
      displayInNavigation: true,
      displayTitleInDrawer: true,
      titleNoMarginBottom: true,
    },
    actorDefinedRoute: {
      showToPro:true,
      showToCustomer: false
    }
  },
  {
    name: 'routes.summary',
    path: RoutePaths.SummaryPath,
    sideDrawerContent: <SummarySideBarContent />,
    displaySettings: {
      displayInNavigation: true,
      displayTitleInDrawer: true,
      titleNoMarginBottom: true,
      centerTitle: true,
    },
    actorDefinedRoute: {
      showToPro:true,
      showToCustomer: true
    }
  },
];
