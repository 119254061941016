import { useRef, FunctionComponent } from 'react';
import { DoubleSide, Euler, Mesh } from 'three';

interface ShadowPlaneProps {
  position: [number, number, number]
  rotation?: [number, number, number]
  size: [number, number]
}

export const ShadowCastingPlane: FunctionComponent<ShadowPlaneProps> = ({
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  size = [1, 1],
}) =>  {
  const planeRef = useRef<Mesh>(null);

  return (
    <mesh
      ref={planeRef}
      position={position}
      rotation={new Euler(...rotation)}
      castShadow
    >
      <planeGeometry args={size} />
      <meshBasicMaterial
        transparent
        opacity={0}
        // color="red"
        // opacity={1}
        side={DoubleSide}
      />
    </mesh>
  );
}
