import React, {FunctionComponent, useMemo} from "react";
import * as THREE from "three";
import {fromMmToRenderingMeters} from "@hec/core";
import {MeshStandardMaterial} from "three";
import {createRollerShutterShape} from "../CreateRollerShutterShape";
import {FrameOverlayRollerShuttersProps} from "./FrameOverlayRollerShuttersProps";
import {GuideRailRendererProps} from "./FrameOverlayRollerShuttersRenderer";
import {OPENING_FRAME_ROD} from "../../../../../constants";
import {DormerColorMaterial} from "../../DormerColorMaterial";

const GuideRailRenderer: FunctionComponent<GuideRailRendererProps> = ({
                                                                        heightMM,
                                                                        innerDepthMM,
                                                                        widthMM
                                                                      }: GuideRailRendererProps) => {
  const shape = new THREE.Shape();

  const height = fromMmToRenderingMeters(heightMM);
  const innerDepth = fromMmToRenderingMeters(innerDepthMM);
  const width = fromMmToRenderingMeters(widthMM);
  const wallThickNess = fromMmToRenderingMeters(2);

  // A
  shape.moveTo(0, 0);
  // B
  shape.lineTo(width, 0);
  // C
  shape.lineTo(width, wallThickNess);
  // D
  shape.lineTo(wallThickNess, wallThickNess);
  // E
  shape.lineTo(wallThickNess, innerDepth + wallThickNess);
  // F
  shape.lineTo(width, innerDepth + wallThickNess);
  // G
  shape.lineTo(width, innerDepth + wallThickNess * 2);
  // H
  shape.lineTo(0, innerDepth + wallThickNess * 2);
  shape.lineTo(0, 0);

  shape.closePath();

  return <extrudeGeometry args={[shape, {depth: height, bevelEnabled: false}]}/>
}

const spaceBetweenLamelHeight = fromMmToRenderingMeters(5);

export const FrameOverlayRollerShuttersRoofWindowRenderer: FunctionComponent<FrameOverlayRollerShuttersProps> =
  ({startCoordinateX, endCoordinateX, width, totalHeight, yOffSetFrames, primaryColor, secondaryColor}: FrameOverlayRollerShuttersProps) => {

    const materialPrimary = <DormerColorMaterial
      dormerColor={primaryColor}
    />;

    const materialSiderod = <DormerColorMaterial
      dormerColor={secondaryColor ?? primaryColor}
    />

    const frameRabbetSideThickness = fromMmToRenderingMeters(OPENING_FRAME_ROD);
    // Naar spec: https://www.rolluiken33.nl/alutech-basisline-rolluik.html

    const renderingWidth = fromMmToRenderingMeters(width);
    // We are supposed to cover this distance with rolling parts only,
    // so the rails dont count over the renderingWidth hence we need to add the rails distance.
    const guideRailWidthMM = 22;
    const guideRailWidth = fromMmToRenderingMeters(guideRailWidthMM);
    // renderingWidth += guideRailWidth * 2;

    const roundingDistance = fromMmToRenderingMeters(50);
    const roundingDistanceTop = fromMmToRenderingMeters(80);
    const renderingHeightMM = totalHeight - OPENING_FRAME_ROD * 3;
    const renderingHeight = fromMmToRenderingMeters(renderingHeightMM);
    const underBarDepth = frameRabbetSideThickness / 2;
    const topBarHeight = renderingHeight - (roundingDistanceTop);
    // const barRenderingHeight = renderingHeight - roundingDistanceTop;

    const lamelHeightMm = 40;
    const lamelHeight = fromMmToRenderingMeters(lamelHeightMm)
    const lamelDepth = fromMmToRenderingMeters(9);

    const amountOfLammellen = Math.round(renderingHeightMM / lamelHeightMm / 3);


    const shape = useMemo(() => createRollerShutterShape(), []);

    const gapSpace = fromMmToRenderingMeters(15);
    const betweenGapSpace = fromMmToRenderingMeters(17);
    const gapTotal = gapSpace + betweenGapSpace;

    const gridRepeatForGapX = Math.ceil(renderingWidth / gapTotal);

    // const sideRod = <mesh key={'siderod'} castShadow={true} receiveShadow={true}>
    //   <GuideRailRenderer
    //     heightMM={renderingHeightMM}
    //     innerDepthMM={8}
    //     widthMM={guideRailWidthMM}
    //   />
    //   <meshStandardMaterial color={'grey'}/>
    // </mesh>;

    const bottomBarDepth = fromMmToRenderingMeters(6);
    const bottomBarHeight = fromMmToRenderingMeters(50);

    const gapCreationRodHeight = amountOfLammellen * (lamelHeight + spaceBetweenLamelHeight);

    const material = useMemo(() => new MeshStandardMaterial({
      color: 'green'
    }), []);

    const bar = <extrudeGeometry args={[shape, {depth: renderingWidth, bevelEnabled: false, curveSegments: 4}]}/>;


    const sideRod = (<group>
        <group position={[-guideRailWidth, 0, frameRabbetSideThickness]}>
          <mesh position={[guideRailWidth / 2, renderingHeight / 2, 0 - underBarDepth / 2]} castShadow={true} receiveShadow={true}>
            <boxGeometry
              args={[guideRailWidth, renderingHeight, underBarDepth]}
            />
            {materialSiderod}
          </mesh>
        </group>
        <group
          position={[-guideRailWidth, roundingDistance, frameRabbetSideThickness + roundingDistance]}>
          <mesh position={[guideRailWidth / 2, topBarHeight / 2, 0 - roundingDistance / 2]} castShadow={true} receiveShadow={true}>
            <boxGeometry
              args={[guideRailWidth, topBarHeight, roundingDistance]}
            />
            {materialSiderod}
          </mesh>
        </group>
        <group position={[-guideRailWidth, 0, frameRabbetSideThickness]}>
          <mesh rotation={[0, 0, Math.PI / 2]} position={[guideRailWidth / 2, roundingDistance, 0]} castShadow={true} receiveShadow={true}>
            <cylinderGeometry
              args={[
                // radiusTop: 1,
                roundingDistance,
                // radiusBottom: 1,
                roundingDistance,
                // height: 1,
                guideRailWidth,
                // radialSegments: 6,
                6,
                // heightSegments: 1,
                1,
                // openEnded: false,
                false,
                // thetaStart: 0,
                -Math.PI / 2,
                // thetaLength: Math.PI
                Math.PI / 2
              ]}
            />
            {materialSiderod}
          </mesh>
        </group>
      </group>
    );


    const middleRodWidth = renderingWidth + guideRailWidth *2;

    return <group position={[fromMmToRenderingMeters(startCoordinateX), yOffSetFrames, 0]}>
      <group>


        <group key={`siderod-west`}>
          {sideRod}
        </group>

        <group
          key={'middle-rod'}
          position={[-middleRodWidth/2 + guideRailWidth, renderingHeight - roundingDistanceTop * 2, frameRabbetSideThickness]}>
          <mesh rotation={[0, 0, Math.PI / 2]} position={[renderingWidth, roundingDistanceTop, 0]} castShadow={true} receiveShadow={true}>
            <cylinderGeometry
              args={[
                // radiusTop: 1,
                roundingDistanceTop,
                // radiusBottom: 1,
                roundingDistanceTop,
                // height: 1,
                middleRodWidth,
                // radialSegments: 6,
                4,
                // heightSegments: 1,
                1,
                // openEnded: false,
                false,
                // thetaStart: 0,
                -Math.PI / 2,
                // thetaLength: Math.PI
                Math.PI
              ]}
            />
            {materialSiderod}
          </mesh>
        </group>

        <group key={`siderod-east`} position={[renderingWidth + guideRailWidth, 0, 0]}>
          {sideRod}
        </group>


      </group>
      <group position={[(renderingWidth / 2), 0, frameRabbetSideThickness + roundingDistance]}>
        <group key={'lamellen'} position={[0, (renderingHeight - gapCreationRodHeight) - roundingDistanceTop, 0]}>
          <group key={'rows'}>
            {Array.from({length: amountOfLammellen}).map((_, index) =>
              (<group
                position={[-renderingWidth / 2, index * (lamelHeight + spaceBetweenLamelHeight), 0]}
                rotation={[0, Math.PI / 2, 0]}>
                <mesh castShadow={true} receiveShadow={true}>
                  {bar}
                  {materialPrimary}
                </mesh>
              </group>))
            }
          </group>
          <group
            position={[-renderingWidth / 2, gapCreationRodHeight / 2 + -spaceBetweenLamelHeight, -lamelDepth / 2 + fromMmToRenderingMeters(1) / 2]}>
            {Array.from({length: gridRepeatForGapX}).map((_, index) =>
              (<group>
                <mesh position={[index * gapTotal + (betweenGapSpace / 2), 0, 0]} castShadow={true} receiveShadow={true}>
                  <boxGeometry args={[betweenGapSpace, gapCreationRodHeight, fromMmToRenderingMeters(2)]}/>
                  {materialPrimary}
                </mesh>
              </group>))
            }
          </group>
          <mesh key={`bottomBar`}
                position={[0, -(bottomBarHeight / 2), -bottomBarDepth / 2]}
                castShadow={true} receiveShadow={true}
          >
            <boxGeometry
              args={[renderingWidth, bottomBarHeight, bottomBarDepth]}
            />
            {materialSiderod}
          </mesh>
        </group>
        {/*<group*/}
        {/*  key={'siderod-left'}*/}
        {/*  rotation={[Math.PI / 2, 0, 0]}*/}
        {/*  position={[-renderingWidth / 2, renderingHeight, -fromMmToRenderingMeters(10)]}*/}
        {/*>*/}
        {/*  {sideRod}*/}
        {/*</group>*/}
        {/*<group*/}
        {/*  key={'siderod-right'}*/}
        {/*  rotation={[Math.PI / 2, Math.PI, 0]}*/}
        {/*  position={[renderingWidth / 2, 0, -fromMmToRenderingMeters(10)]}*/}
        {/*>*/}
        {/*  {sideRod}*/}
        {/*</group>*/}
      </group>
    </group>
  }
