import produce from 'immer';
import {getSelectableSectionFragmentsSucceededAction} from './Actions';
import {SelectableSectionFragmentsState} from "./State";

export const getSelectableSectionFragmentsSucceededReducer = (state: SelectableSectionFragmentsState, action: ReturnType<typeof getSelectableSectionFragmentsSucceededAction>) => {
  return produce(state, (draft) => {
    draft.selectableSectionFragments = action.payload.sectionFragmentsDto
      .filter(sectionFragment => sectionFragment.isUserSelectable);
  });
}
