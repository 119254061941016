import {
  CheeksDto, FaceDto,
  FrameSurroundingRodDto,
  OrderDto,
  RoofTrimDto, SectionFragmentDto,
  SectionFragmentType,
  SplinterDto,
  StubbedDto
} from "@hec/api-dtos";
import {getSectionFragmentsFromOrder, getSectionFragmentsFromOrderByType} from "./Util";
import {DormerRootState} from "../DormerRootState";

export const getCheeks = (state: DormerRootState): CheeksDto | null => {
  const order = state.order.order;
  if (!order) {
    return null;
  }

  const cheeks = getSectionFragmentsFromOrderByType(order, SectionFragmentType.Cheeks)
  if (cheeks.length === 0) {
    return null;
  } else {
    return cheeks[0] as CheeksDto;
  }
}

export const getSplinter = (state: DormerRootState): SplinterDto | null => {
  const order= state.order.order;
  if (!order) {
    return null;
  }

  const splinters = getSectionFragmentsFromOrderByType(order, SectionFragmentType.Splinter)
  if (splinters.length === 0) {
    return null;
  } else {
    return splinters[0] as SplinterDto;
  }
}

export const getStubbed = (state: DormerRootState): StubbedDto | null => {
  const order= state.order.order;
  if (!order) {
    return null;
  }

  const stubbed = getSectionFragmentsFromOrderByType(order, SectionFragmentType.Stubbed)
  if (stubbed.length === 0) {
    return null;
  } else {
    return stubbed[0] as StubbedDto;
  }
}

export const getRoofTrim = (state: DormerRootState): RoofTrimDto | null => {
  const order= state.order.order;
  if (!order) {
    return null;
  }

  const roofTrim = getSectionFragmentsFromOrderByType(order, SectionFragmentType.RoofTrim)
  if (roofTrim.length === 0) {
    return null;
  } else {
    return roofTrim[0] as RoofTrimDto;
  }
}

export const getFrameSurroundingRod = (state: DormerRootState): FrameSurroundingRodDto | null => {
  const order= state.order.order;
  if (!order) {
    return null;
  }

  const frameSurroundingRod = getSectionFragmentsFromOrderByType(order, SectionFragmentType.FrameSurroundingRod)
  if (frameSurroundingRod.length === 0) {
    return null;
  } else {
    return frameSurroundingRod[0] as FrameSurroundingRodDto;
  }
}

export const getSectionFragmentsFromFace = (face: FaceDto): SectionFragmentDto[] => {
  return face?.sectionFragments ?? [];
}


export const getSectionFragmentsFromFaceByType = (face: FaceDto, sectionFragmentType: SectionFragmentType): SectionFragmentDto[] => {
  const sectionFragments = getSectionFragmentsFromFace(face)
  const result = sectionFragments
    .filter((sectionFragment) => sectionFragment.sectionFragmentType === sectionFragmentType);
  return (result as SectionFragmentDto[]);
}


// export const getRollerShutters = (state: DormerRootState): RollerShuttersDto | null => {
//   const order= state.order.order;
//   if (!order) {
//     return null;
//   }
//
//   const rollerShutters = getSectionFragmentsFromOrderByType(order, SectionFragmentType.RollerShutters)
//   if (rollerShutters.length === 0) {
//     return null;
//   } else {
//     return rollerShutters[0] as RollerShuttersDto;
//   }
// }
