import React, {FunctionComponent} from "react";
import {
  ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType,
  FaceDto, FrameSurroundingRodDto,
  SectionFragmentDto,
  SectionFragmentType
} from "@hec/api-dtos";
import {Box} from "@react-three/drei";
import {fromMmToRenderingMeters, getFrameHeight} from "@hec/core";
import {REALLY_SMALL_NUMBER, SPLINTER_DEPTH} from "../../../../constants";
import {DormerColorMaterial} from "../DormerColorMaterial";
import {SectionFragmentsFrameRenderer} from "./SectionFragmentsFrameRenderer";
import {GroupByConsecutivePanelType, SimpleFrameContainer, ConsecutivePanelTypeGroup, ToSimpleFrameContainer} from '../../../index';
import {Base3dProps} from "../Base3dProps";
import {Conditional, GroupHelper} from "@hec/components/v2";
import {makeInspectable} from "@hec/dal";


export interface FrameGroupsRendererProps extends Base3dProps {
  face: FaceDto;
}

/// Frame groups exist because multiple frames get a frame of their own.
export const FrameGroupsRenderer: FunctionComponent<FrameGroupsRendererProps> = ({face, visualizationSettings}: FrameGroupsRendererProps) => {
  const sections = makeInspectable(face.sectionFragments);

  const frameSurroundingRodThickness = fromMmToRenderingMeters(visualizationSettings.sizeFrameSurroundingRod);

  const sectionFragments = face?.sectionFragments ?? [];
  const ungroupedFrames = sectionFragments.filter(x => x.sectionFragmentType === SectionFragmentType.Frame);

  const frameSurroundingRod : FrameSurroundingRodDto | undefined = sectionFragments.find(x=> x.sectionFragmentType === SectionFragmentType.FrameSurroundingRod);


  // Grouped this way for easier debugging.
  const simpleFrameContainers: SimpleFrameContainer[] = ToSimpleFrameContainer(ungroupedFrames);
  const groupedByVariation:ConsecutivePanelTypeGroup[] = GroupByConsecutivePanelType(simpleFrameContainers, visualizationSettings.visualizationType === ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType.Slanted);

  const result = groupedByVariation.map((groupedModels: ConsecutivePanelTypeGroup) => {
    const frameInsetHeight = fromMmToRenderingMeters(getFrameHeight(face));

    let rodWidth = frameSurroundingRodThickness;
    let rodHeight = frameSurroundingRodThickness;
    if(groupedModels.models.find(x=> (x).isPanel) != null) {
      rodWidth = 0;
      rodHeight = 0;
      // This is probably wrong and calculation should be corrected. TODO: Check
      // frameInsetHeight += frameSurroundingRodThickness*2;
    }


    const frames = groupedModels.models.map((x: SimpleFrameContainer) => x.frame);

    const totalWidth = groupedModels.width;//fromMmToRenderingMeters(getWidthOfFrames(frames));

    const frame: SectionFragmentDto | undefined = frames.find(x=> !!x.configuredDormerColor);

    const frameSurroundingRodMaterial = frameSurroundingRod?.configuredDormerColor ?? frame?.configuredDormerColor ?? null


    // Sum the totals of all previous indexes of groupedModels
    const previousGroupTotalWidth = groupedByVariation
      .slice(0, groupedByVariation.indexOf(groupedModels))
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue.width
      }, 0);

    const depthOffset = visualizationSettings.visualizationType === ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType.Slanted
    && groupedModels.models.find(x => !x.isPanel)
      ? SPLINTER_DEPTH : 0;

    // const depthOffset = groupedModels.models.find(x => !x.isPanel) ? visualizationSettings.offsetFrameDepthNonWindowVariation : visualizationSettings.offsetFrameDepthWindowVariation

    return <group key={`frame-group-${groupedByVariation.indexOf(groupedModels)}`} position={[previousGroupTotalWidth,0,depthOffset]}>
      <SectionFragmentsFrameRenderer
        sectionFragments={groupedModels.models.map(x=> x.frame)}
        visualizationSettings={visualizationSettings}
        frameSurroundingRod={frameSurroundingRod}
      />

      <Conditional condition={rodWidth > REALLY_SMALL_NUMBER}>
        <group key={'frame-surrounding-rod'} position={[(totalWidth / 2), (rodHeight / 2), -(SPLINTER_DEPTH / 2)]}>
          <group key={'left-frame-surrounding-rod'}
                 position={[-(totalWidth - rodWidth) / 2, ((frameInsetHeight - rodHeight) / 2), 0]}>
            <Box
              args={[rodWidth, frameInsetHeight, SPLINTER_DEPTH]}
              receiveShadow
              castShadow
            >
              <DormerColorMaterial
                dormerColor={frameSurroundingRodMaterial}
                width={rodWidth}
                height={frameInsetHeight}
              />
            </Box>
          </group>

          <group key={'right-frame-surrounding-rod'}
                 position={[(totalWidth - rodWidth) / 2, ((frameInsetHeight - rodHeight) / 2), 0]}>
            <Box
              args={[rodWidth, frameInsetHeight, SPLINTER_DEPTH]}
              receiveShadow
              castShadow
            >
              <DormerColorMaterial
                dormerColor={frameSurroundingRodMaterial}
                width={rodWidth}
                height={frameInsetHeight}
              />
            </Box>
          </group>
          <group key={'top-frame-surrounding-rod'} position={[0, frameInsetHeight - rodHeight, 0]}>
            <Box
              args={[totalWidth, rodHeight, SPLINTER_DEPTH]}
              receiveShadow
              castShadow
            >
              <DormerColorMaterial
                dormerColor={frameSurroundingRodMaterial}
                width={totalWidth}
                height={rodHeight}
              />
            </Box>
          </group>
          <group key={'bottom-frame-surrounding-rod'} position={[0, 0, 0]}>
            <Box
              args={[totalWidth, rodHeight, SPLINTER_DEPTH]}
              receiveShadow
              castShadow
            >
              <DormerColorMaterial
                dormerColor={frameSurroundingRodMaterial}
                width={totalWidth}
                height={rodHeight}
              />
            </Box>
          </group>
        </group>
      </Conditional>
    </group>
  });
  return <group key={'frames-w-surrouding-rods'}>
    {result}
  </group>
}
