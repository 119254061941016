import React, {FunctionComponent} from "react";
import {DormerColorViewModel, FaceDto, SectionFragmentType} from "@hec/api-dtos";
import {
  CdnUrlHelper,
  fromMmToRenderingMeters,
  getTotalHeightExcludingStubbed,
  getTotalWidth
} from "@hec/core";
import {DEFAULT_DORMER_DEPTH} from "../Dormer3dRenderer";
import {useLoader} from "@react-three/fiber";
import {RepeatWrapping, TextureLoader} from "three";
import {TEXTURES_PER_METER_IN_LENGTH} from "../../../constants";
import {Box} from "@react-three/drei";
import { CustomPrism, CustomPrismCalculationHelper} from "./CustomPrism";


export interface SlantedStubbedProps {
  southFace: FaceDto;
  overrideColor?: DormerColorViewModel
}


const cdnPublicRoot = CdnUrlHelper.getPublicRoot();
export const SlantedStubbed: FunctionComponent<SlantedStubbedProps> = ({
                                                                         overrideColor,
                                                                         southFace
                                                                       }: SlantedStubbedProps) => {
  const southSectionFragments = southFace.sectionFragments;
  const stubbedSectionFragment = southSectionFragments.find(x => x.sectionFragmentType === SectionFragmentType.Stubbed);

  const stubbedHeight = fromMmToRenderingMeters(stubbedSectionFragment?.height ?? -1);


  const stubbedWidth = fromMmToRenderingMeters(getTotalWidth(southFace));

  const depth = DEFAULT_DORMER_DEPTH;


  const roofTexture = useLoader(
    TextureLoader,
    cdnPublicRoot + '/roof/Texture.jpg'
  );

  const repeatMaxX = TEXTURES_PER_METER_IN_LENGTH * stubbedWidth;
  const repeatMaxY = TEXTURES_PER_METER_IN_LENGTH * depth;

  roofTexture.wrapS = RepeatWrapping;
  roofTexture.wrapT = RepeatWrapping;
  roofTexture.repeat.set(repeatMaxX, repeatMaxY);

  const roofLeatherHeight = .0001;


  const cheekHeight = fromMmToRenderingMeters(getTotalHeightExcludingStubbed(southFace));


  const slantedCheeksCausedDepthOffset = CustomPrismCalculationHelper(cheekHeight).opposite;
  const slantedStubbedHeightCausedDepthOffset = CustomPrismCalculationHelper(stubbedHeight).opposite;


  return (
    <group key={'stubbed'} position={[0, 0, -(slantedCheeksCausedDepthOffset)]}>
      <group position={[(stubbedWidth / 2), stubbedHeight + roofLeatherHeight, -(depth / 2 + slantedStubbedHeightCausedDepthOffset + 0.02)]}>
        <Box
          args={[stubbedWidth, roofLeatherHeight, depth]}
          castShadow={false}
          receiveShadow={true}
        >
          <meshStandardMaterial
            map={roofTexture}
            displacementScale={0}
            clipIntersection={true}
          />
        </Box>
      </group>
      {/*<group position={[(stubbedWidth / 2), (stubbedHeight / 2), 0]}>*/}
      <group position={[0, 0, 0]}>
        <CustomPrism
          dormerColor={overrideColor}
          yHeight={stubbedHeight}
          slantedSideWidth={stubbedWidth}
          topWidth={depth}
        />
      </group>

      {/*<group position={[(stubbedWidth / 2), .05, -(depth / 2)]}>*/}
      {/*  <Box*/}
      {/*    args={[stubbedWidth - 0.01, 0.01, depth - 0.01]}*/}
      {/*    receiveShadow*/}
      {/*    castShadow*/}
      {/*  >*/}
      {/*    <meshStandardMaterial/>*/}
      {/*  </Box>*/}
      {/*</group>*/}


      )
    </group>);
}
