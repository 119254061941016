import {fromMmToRenderingMeters} from "@hec/core";

export * from './FrameOverlayRollerShuttersRenderer';
export * from './FrameOverlayRollerShuttersRoofWindowRenderer';
export * from './FrameOverlayRollerBlindsRenderer';

export const distanceFromZeroZ = fromMmToRenderingMeters(10);


export const screenTexture = "https://cdn.aanbouwconfigurator.com/static-assets/generic/frameoverlays/screen-texture-screen-v13.png";
