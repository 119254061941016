import {TranslationsDormer} from "./TranslationsDormer";

export const TranslationKeysDormer: TranslationsDormer = {
  requestQuotation: "dormer.requestQuotation",
  measurementUnit: "dormer.measurementUnit",
  sizeInformation: {
    totalDormerWidth: "dormer.sizeInformation.totalDormerWidth",
    totalFrameWidth: "dormer.sizeInformation.totalFrameWidth",
  },
  noPrice: 'dormer.noPrice',
  pages: {
    templatePage: {
      name: "dormer.pages.templatePage.name",
      noTemplateSelectedError: "dormer.pages.templatePage.noTemplateSelectedError",
    },
    measurementsPage: {
      name: "dormer.pages.measurementsPage.name",
      cta: "dormer.pages.measurementsPage.cta",
      widthHeader: "dormer.pages.measurementsPage.widthHeader",
      heightHeader: "dormer.pages.measurementsPage.heightHeader",
      widthMinimumError: "dormer.pages.measurementsPage.widthMinimumError",
      widthMaximumError: "dormer.pages.measurementsPage.widthMaximumError",
      heightMinimumError: "dormer.pages.measurementsPage.heightMinimumError",
      heightMaximumError: "dormer.pages.measurementsPage.heightMaximumError",
    },
    framesPage: {
      name: "dormer.pages.framesPage.name",
      cta: "dormer.pages.framesPage.cta",
      addNewFrame: "dormer.pages.framesPage.addNewFrame",
      freeSpace: "dormer.pages.framesPage.freeSpace",
      exceededSpace: "dormer.pages.framesPage.exceededSpace",
      expandFrame: "dormer.pages.framesPage.expandFrame",
      customButtonLabel: "dormer.pages.framesPage.customButtonLabel",
      preSelectedButtonLabel: "dormer.pages.framesPage.preSelectedButtonLabel",
      shrinkFreeButtonSpace: "dormer.pages.framesPage.shrinkFreeButtonSpace",
    },
    materialPage: {
      name: "dormer.pages.materialPage.name",
      cta: "dormer.pages.materialPage.cta",
    },
    extrasPage: {
      name: "dormer.pages.extrasPage.name",
      cta: "dormer.pages.extrasPage.cta",
      rooftrimDropdownTitle: "dormer.pages.extrasPage.rooftrimDropdownTitle",
      rooftrimColorDropdownTitle: "dormer.pages.extrasPage.rooftrimColorDropdownTitle",
      exteriorSolarControlDropdownTitle: "dormer.pages.extrasPage.exteriorSolarControlDropdownTitle",
      exteriorSolarColorDropdownTitle: "dormer.pages.extrasPage.exteriorSolarColorDropdownTitle",
      uniqueGroupTitlePrefix: "dormer.pages.extrasPage.uniqueGroupTitlePrefix",
    },
    coloursPage: {
      name: "dormer.pages.coloursPage.name",
      cta: "dormer.pages.coloursPage.cta",
    },
    finishPage: {
      name: "dormer.pages.finishPage.name",
      cta: "dormer.pages.finishPage.cta",
    },
    technicalPage: {
      name: "dormer.pages.technicalPage.name",
      cta: "dormer.pages.technicalPage.cta",
    },
    customerSurveyPage: {
      name: "dormer.pages.customerSurveyPage.name",
      cta: "dormer.pages.customerSurveyPage.cta",
    },
    summaryPage: {
      name: "dormer.pages.summaryPage.name",
      cta: "dormer.pages.summaryPage.cta",
      summary: "dormer.pages.summaryPage.summary",
      indication: "dormer.pages.summaryPage.indication",
    }
  },
}
